import React from "react";
import { Table, Modal, Button, message } from "antd";
import sualtap from "./../../const/api";

const columns = [
  {
    title: "Ad və soyad",
    dataIndex: "name",
    key: "name",
    render: text => <a href="/">{text}</a>
  },
  {
    title: "Mövzu",
    dataIndex: "topic",
    key: "topic"
  },
  {
    title: "Əməliyyat",
    dataIndex: "operation",
    key: "operation"
  },
  {
    title: "Status",
    dataIndex: "done",
    key: "done"
  }
];

class Contacts extends React.Component {
  state = {
    contacts: [],
    visible: false,
    selected: 0
  };

  fetchContacts = async () => {
    await sualtap.get("contacts").then(res =>
      this.setState({
        contacts: res.data
      })
    );
  };

  componentDidMount() {
    this.fetchContacts();
  }

  showModal = id => {
    this.setState({
      visible: true,
      selected: id
    });
  };

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  deleteMessage = async id => {
    await sualtap.delete(`contacts/${id}`).then(res => {
      message.success("Mesaj silindi!");
      this.fetchContacts();
    });
  };

  updateMessage = async id => {
    let cont = this.state.contacts.filter(c => c.id === id)[0];
    cont.isHandled = !cont.isHandled;
    await sualtap.put(`contacts/${id}`, cont).then(res => {
      message.success("Baxıldı!");
      this.fetchContacts();
    });
  };

  render() {
    const dataSource = this.state.contacts.map(c => ({
      key: c.id,
      ...c,
      name: c.name + " " + c.surname,
      operation: (
        <>
        <Button onClick={() => this.showModal(c.id)}>Bax</Button>
        <Button style={{margin: "0 5px"}} onClick={() => this.updateMessage(c.id)}>Statusu dəyiş</Button>
        <Button onClick={() => this.deleteMessage(c.id)}>Sil</Button>
        </>  
      ),
      done: c.isHandled ? "Baxılıb" : "Baxılmayıb"
    }));
    const message = this.state.contacts.find(c => c.id === this.state.selected);

    return (
      <>
        <div className="b-page-top">
          <h2>Mesajlar</h2>
        </div>
        <Table columns={columns} dataSource={dataSource} />
        <Modal
          title="Mesaj"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <div className="message-part">
            <span>Ad: </span>
            {message ? message.name : ""}
          </div>
          <div className="message-part">
            <span>Soyad: </span>
            {message ? message.surname : ""}
          </div>
          <div className="message-part">
            <span>Email: </span>
            {message ? message.email : ""}
          </div>
          <div className="message-part">
            <span>Telefon: </span>
            {message ? message.phone : ""}
          </div>
          <div className="message-part">
            <span>Mövzu: </span>
            {message ? message.topic : ""}
          </div>
          <div className="message-part">
            <span>Mesaj: </span>
            {message ? message.message : ""}
          </div>
        </Modal>
      </>
    );
  }
}

export default Contacts;
