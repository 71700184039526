import React from "react";
import { Input, Select, Switch, Spin, InputNumber, Button, message } from "antd";
import sualtap from "../../../const/api";
const { Option } = Select;

class Package extends React.Component {
  constructor(props) {
    super(props);
    this.lastAuthorFetchId = 0;
    this.lastTagFetchId = 0;
    this.state = {
      allAuthors: [],
      selectedAuthors: [],
      authors: [],
      tags: [],
      selectedTags: [],
      allTags: [],
      authorsFetching: false,
      tagsFetching: false,
      packageID: 1,
      package: {}
    };
  }

  fetchPackage = async () => {
    await sualtap.get(`packages/${this.state.packageID}`).then(res => {
      let arr = [];
      res.data.editors.forEach(el => {
        const label = this.state.allAuthors.find(l => l.id === el.author_ID)
          .fullname;
        arr.push({ key: el.author_ID.toString(), label });
      });
      let arr2 = [];
      res.data.package_tags.forEach(el => {
        const label = this.state.allTags.find(l => l.id === el.tag_ID).name;
        arr2.push({ key: el.tag_ID.toString(), label });
      });
      this.setState({
        package: res.data,
        selectedAuthors: arr,
        selectedTags: arr2
      });
    });
  };

  handleUserID = e => {
    const pack = { ...this.state.package };
    pack.user_ID = e;
    this.setState({
      package: pack
    });
  };
  handleGameType = e => {
    const pack = { ...this.state.package };
    pack.game_type_ID = e;
    this.setState({
      package: pack
    });
  };

  handleIsTranslatedChange = e => {
    const pack = {...this.state.package};
    pack.isNotOriginal = this.state.package.isNotOriginal ? false : true;
    console.log(pack);
    this.setState({ package: pack });
  }

  handlePackageName = e => {
    const pack = { ...this.state.package };
    pack.name = e.target.value;
    this.setState({
      package: pack
    });
  };
  handlePackID = e => {
    this.setState({ packageID: e });
  };

  changePackage = async () => {
    await sualtap
      .put(`packages/${this.state.package.id}`, this.state.package)
      .then(res => {
        message.success("Dəyişiklik edildi");
        this.setState({
          package: {}
        });
      });
  };

  // Authors search
  filterAuthorsSearch = async value => {
    this.lastAuthorFetchId += 1;
    const authorFetchId = this.lastAuthorFetchId;
    this.setState({ authors: [], authorsFetching: true });
    let authores = this.state.allAuthors.filter(a =>
      a.fullname.toLowerCase().includes(value.toLowerCase())
    );
    if (authorFetchId !== this.lastAuthorFetchId) {
      return;
    }
    this.setState({ authors: authores, authorsFetching: false });
  };
  filterTagsSearch = async value => {
    this.lastTagFetchId += 1;
    const tagFetchId = this.lastTagFetchId;
    this.setState({ tags: [], tagsFetching: true });
    let tages = this.state.allTags.filter(a =>
      a.name.toLowerCase().includes(value.toLowerCase())
    );
    if (tagFetchId !== this.lastTagFetchId) {
      return;
    }
    this.setState({ tags: tages, tagsFetching: false });
  };

  handleAuthorsSearchChange = value => {
    this.setState({
      selectedAuthors: value,
      authors: [],
      authorsFetching: false
    });
  };
  handleTagsSearchChange = value => {
    this.setState({
      selectedTags: value,
      tags: [],
      tagsFetching: false
    });
  };
  fetchAuthors = async () => {
    await sualtap.get("authors").then(res => {
      this.setState({
        allAuthors: res.data
      });
    });
  };
  fetchTags = async () => {
    await sualtap.get("tags").then(res => {
      this.setState({
        allTags: res.data
      });
    });
  };

  //child question
  componentDidMount = async () => {
    await this.fetchAuthors();
    await this.fetchTags();
  };

  fixAuthors = async () => {
    let selected = [];
    this.state.selectedAuthors.forEach(el => {
      selected.push(+el.key);
    });
    let old = [];
    this.state.package.editors.forEach(el => {
      old.push(el.author_ID);
    });
    //keep - 1    //update - 2    //delete - 3    //new - 4
    let copySelected = [...selected];
    let copyOld = [...old];
    let process = [];
    copySelected.forEach(el => {
      if (copyOld.includes(el)) {
        process.push({ action: 1, id: el, val: null });
        copySelected = copySelected.filter(a => a !== el);
        copyOld = copyOld.filter(a => a !== el);
      }
    });
    copyOld.forEach(el => {
      if (copySelected.length) {
        process.push({ action: 2, id: el, val: copySelected[0] });
        copySelected.shift();
        copyOld = copyOld.filter(a => a !== el);
      } else {
        process.push({ action: 3, id: el, val: null });
      }
    });
    copySelected.forEach(el => {
      process.push({ action: 4, id: el, val: null });
    });
    let success = true;
    process.forEach(el => {
      switch (el.action) {
        case 1:
          return;
        case 2:
          let author_update = this.state.package.editors.filter(
            ao => ao.author_ID === el.id
          )[0];
          author_update.author_ID = el.val;
          sualtap
            .put(`editors/${author_update.id}`, author_update)
            .then(error => (success = false));
          break;
        case 3:
          let author_delete = this.state.package.editors.filter(
            ao => ao.author_ID === el.id
          )[0];
          sualtap
            .delete(`editors/${author_delete.id}`)
            .then(error => (success = false));
          break;
        case 4:
          let author_new = {
            author_ID: el.id,
            package_ID: this.state.package.id
          };
          console.log(author_new);
          sualtap.post("editors", author_new).then(error => (success = false));
          break;
        default:
          break;
      }
    });
    if (success) {
      message.success("Redaktorlarda düzəliş edildi");
    }
  };

  fixTags = async () => {
    let selected = [];
    this.state.selectedTags.forEach(el => {
      selected.push(+el.key);
    });
    let old = [];
    this.state.package.package_tags.forEach(el => {
      old.push(el.tag_ID);
    });
    //keep - 1    //update - 2    //delete - 3    //new - 4
    let copySelected = [...selected];
    let copyOld = [...old];
    let process = [];
    copySelected.forEach(el => {
      if (copyOld.includes(el)) {
        process.push({ action: 1, id: el, val: null });
        copySelected = copySelected.filter(a => a !== el);
        copyOld = copyOld.filter(a => a !== el);
      }
    });
    copyOld.forEach(el => {
      if (copySelected.length) {
        process.push({ action: 2, id: el, val: copySelected[0] });
        copySelected.shift();
        copyOld = copyOld.filter(a => a !== el);
      } else {
        process.push({ action: 3, id: el, val: null });
      }
    });
    copySelected.forEach(el => {
      process.push({ action: 4, id: el, val: null });
    });
    let success = true;
    process.forEach(el => {
      switch (el.action) {
        case 1:
          return;
        case 2:
          let tag_update = this.state.package.package_tags.filter(
            ao => ao.tag_ID === el.id
          )[0];
          tag_update.tag_ID = el.val;
          sualtap
            .put(`package_tag/${tag_update.id}`, tag_update)
            .then(error => (success = false));
          break;
        case 3:
          let tag_delete = this.state.package.package_tags.filter(
            ao => ao.tag_ID === el.id
          )[0];
          sualtap
            .delete(`package_tag/${tag_delete.id}`)
            .then(error => (success = false));
          break;
        case 4:
          let tag_new = {
            tag_ID: el.id,
            package_ID: this.state.package.ID
          };
          sualtap.post("package_tag", tag_new).then(error => (success = false));
          break;
        default:
          break;
      }
    });
    if (success) {
      message.success("Redaktorlarda düzəliş edildi");
    }
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Paket</h2>
        </div>
        <div className="b-add-box">
          <div className="b-add-box-left">
            <div className="b-100">
              <label>Package</label>
              <InputNumber
                value={this.state.packageID}
                onChange={e => this.handlePackID(e)}
                type="number"
              />
              <Button onClick={this.fetchPackage} type="primary">
                Paketi gətir
              </Button>
            </div>
            {this.state.package.user_ID ? (
              <>
                <Switch
                  checkedChildren="Paket yığılmadır"
                  onChange={this.handleIsTranslatedChange}
                  checked={this.state.package.isNotOriginal}
                  unCheckedChildren="Paket originaldır"
                  defaultChecked
                />
                <div className="b-100">
                  <Input
                    value={this.state.package.name}
                    onChange={e => this.handlePackageName(e)}
                  />
                </div>
                <div className="b-100">
                  <label>User</label>
                  <InputNumber
                    type="number"
                    value={this.state.package.user_ID}
                    onChange={e => this.handleUserID(e)}
                  />
                  <Button onClick={this.changePackage} type="primary">
                    Dəyişiklik et
                  </Button>
                </div>
                <div className="b-100">
                  <div className="edit-question">
                    <label>Game</label>
                    <InputNumber
                      type="number"
                      value={this.state.package.game_type_ID}
                      onChange={e => this.handleGameType(e)}
                    />
                    <div className="label-field">
                      <Select
                        mode="multiple"
                        labelInValue
                        value={this.state.selectedAuthors}
                        placeholder="Müəllifləri seç"
                        notFoundContent={
                          this.state.authorsFetching ? (
                            <Spin size="small" />
                          ) : null
                        }
                        filterOption={false}
                        onSearch={this.filterAuthorsSearch}
                        onChange={this.handleAuthorsSearchChange}
                        style={{ width: "100%" }}
                      >
                        {this.state.authors.map(a => (
                          <Option key={a.id}>{a.fullname}</Option>
                        ))}
                      </Select>
                      <Button type="primary" onClick={this.fixAuthors}>
                        Redaktorları düzəlt{" "}
                      </Button>
                    </div>
                    <div className="label-field">
                      <Select
                        mode="multiple"
                        labelInValue
                        value={this.state.selectedTags}
                        placeholder="Teqləri seç"
                        notFoundContent={
                          this.state.tagsFetching ? <Spin size="small" /> : null
                        }
                        filterOption={false}
                        onSearch={this.filterTagsSearch}
                        onChange={this.handleTagsSearchChange}
                        style={{ width: "100%" }}
                      >
                        {this.state.tags.map(a => (
                          <Option key={a.id}>{a.name}</Option>
                        ))}
                      </Select>
                      <Button type="primary" onClick={this.fixTags}>
                        Teqləri düzəlt{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="b-add-box-right"></div>
        </div>
      </>
    );
  }
}

export default Package;
