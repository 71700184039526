import React from "react";
import Loader from "./Elements/Loader";
import { connect } from "react-redux";
import LeftMenu from "./Layout/LeftMenu/LeftMenu";
import TopMenu from "./Layout/TopMenu/TopMenu";
import { Route } from "react-router-dom";
import Users from "./Pages/Users";
import Teams from "./Pages/Teams";
import Packages from "./Pages/Packages/Packages";
import Tournament from "./Pages/Tournaments/Tournament";
import Tournaments from "./Pages/Tournaments/Tournaments";
import Organizations from "./Pages/Organizations/Organizations";
import Package from "./Elements/Add/Package";
import Organization from "./Elements/Add/Organization";
import Add from "./Elements/Add/Add";
import See from "./Elements/Add/See";
import Statistics from "./Pages/Statistics";
import Login from "./Layout/Login/Login";
import Phases from "./Pages/Phases";
import { Rules } from "./Pages/Rules";
import Tags from "./Pages/Tags";
import AuthProvider from "./Auth";
import { logUserIn, loadingOn, loadingOff } from "./../redux/actions/index";
import Update from "./Pages/Update/Update";
import Edit from "./Elements/Add/Edit";
import Tedit from "./Elements/Add/Tedit";
import Constants from "./Pages/Constants";
import Contacts from "./Pages/Contacts";

class App extends React.Component {
  componentDidMount() {
    if (localStorage.getItem("sualtapUser")) {
      var nov = new Date();
      var user = JSON.parse(localStorage.getItem("sualtapUser"));
      var then = new Date(user.lastLogin);
      var lastLog = Math.floor(
        (nov.getTime() - then.getTime()) / (1000 * 60 * 60)
      );
      if (lastLog < 10) {
        var data = JSON.parse(localStorage.getItem("sualtapUser"));
        this.props.loadingOn();
        this.props.logUserIn(data);
      }
    }
  }

  render() {
    return (
      <>
        {this.props.isLoading ? <Loader /> : null}
        {this.props.isLoggedIn ? (
          <>
            <div id="page">
              <LeftMenu toggleMenu={this.toggleMenu} />
              <div id="pageRight">
                <TopMenu />
                <Route path="/" exact>
                  <AuthProvider auth={1}>
                    <Statistics />
                  </AuthProvider>
                </Route>
                <Route path="/users">
                  <AuthProvider auth={5}>
                    <Users />
                  </AuthProvider>
                </Route>
                <Route path="/claims">
                  <AuthProvider auth={5}>
                    <Contacts />
                  </AuthProvider>
                </Route>
                <Route path="/teams">
                  <AuthProvider auth={5}>
                    <Teams />
                  </AuthProvider>
                </Route>
                <Route path="/packages">
                  <AuthProvider auth={4}>
                    <Teams />
                  </AuthProvider>
                </Route>
                <Route path="/comments"></Route>
                <Route exact path="/tournaments">
                  <AuthProvider auth={3}>
                    <Tournaments />
                  </AuthProvider>
                </Route>
                <Route exact path="/tournaments/new">
                  <AuthProvider auth={4}>
                    <Tournament />
                  </AuthProvider>
                </Route>
                {this.props.user >= 4 ? <Route path="/claims"></Route> : null}
                <Route path="/authors"></Route>
                <Route path="/messages"></Route>
                <Route exact path="/hidden/edit/question">
                  <AuthProvider auth={5}>
                    <Edit head={true} />
                  </AuthProvider>
                </Route>
                <Route exact path="/hidden/edit/theme">
                  <AuthProvider auth={5}>
                    <Tedit head={true} />
                  </AuthProvider>
                </Route>
                <Route
                  exact
                  path="/edit/question/:id"
                  render={({ match }) => (
                    <AuthProvider auth={3}>
                      <Edit head={false} params={match.params} />
                    </AuthProvider>
                  )}
                />
                <Route
                  exact
                  path="/edit/theme/:id"
                  render={({ match }) => (
                    <AuthProvider auth={3}>
                      <Tedit head={false} params={match.params} />
                    </AuthProvider>
                  )}
                />
                <Route path="/rules">
                  <Rules />
                </Route>
                <Route exact path="/organizations">
                  <AuthProvider auth={4}>
                    <Organizations />
                  </AuthProvider>
                </Route>
                <Route exact path="/organizations/new">
                  <AuthProvider auth={4}>
                    <Organization />
                  </AuthProvider>
                </Route>
                <Route path="/editors"></Route>
                <Route
                  path="/tournament/:id/new/package"
                  render={({ match }) => (
                    <AuthProvider auth={3}>
                      <Package params={match.params} />
                    </AuthProvider>
                  )}
                />
                <Route
                  path="/tournament/:id/packages"
                  render={({ match }) => (
                    <AuthProvider auth={3}>
                      <Packages user={false} params={match.params} />
                    </AuthProvider>
                  )}
                />
                <Route path="/my">
                  <AuthProvider auth={3}>
                    <Packages user={true} />
                  </AuthProvider>
                </Route>
                <Route path="/update">
                  <AuthProvider auth={4}>
                    <Update />
                  </AuthProvider>
                </Route>
                <Route path="/constants">
                  <AuthProvider auth={5}>
                    <Constants user={true} />
                  </AuthProvider>
                </Route>
                <Route
                  path="/phase/:id/new"
                  render={({ match }) => (
                    <AuthProvider auth={3}>
                      <Add params={match.params} />
                    </AuthProvider>
                  )}
                />
                <Route
                  path="/phase/:id/see"
                  render={({ match }) => (
                    <AuthProvider auth={3}>
                      <See params={match.params} />
                    </AuthProvider>
                  )}
                />
                <Route
                  path="/package/:id/phases"
                  render={({ match }) => (
                    <AuthProvider auth={3}>
                      <Phases params={match.params} />
                    </AuthProvider>
                  )}
                />
                <Route path="/brain"></Route>
                <Route path="/individual"></Route>
                {this.props.user === 5 ? (
                  <React.Fragment>
                    <Route path="/tags">
                      <Tags type={false} />
                    </Route>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <Login logIn={this.toggleLoggedIn} />
        )}
      </>
    );
  }
}
const mapStateToProps = ({ user, loader }) => {
  return {
    isLoggedIn: user.isLoggedIn,
    isLoading: loader.isLoading,
    // isLoggedIn: true,
    user: user.data.role,
  };
};

export default connect(mapStateToProps, { logUserIn, loadingOn, loadingOff })(
  App
);
