import React from 'react';

export const Rules = () => {
    return (
        <div className="rules">
            <h2 className="bold">Sual əlavə etmək üçün qaydalar</h2>
            <h4 className="bold">1. Turnir əlavə edilməsi</h4>
            <p>&nbsp;&nbsp;1.1 Turnir müəyyən davamiyyətə sahibdirsə, həmin davamiyyət siyahıdan seçilməlidir (Mələsən: Peşəkar İntellekt Liqası, İstiqlal kuboku və s.)</p>
            <p>&nbsp;&nbsp;1.2 Turnirin liqa, yoxsa bir dəfə keçirilən kubok formatında olduğu seçilməlidir</p>
            <p>&nbsp;&nbsp;1.3 Turnirin adı daxil edilməlidir</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;- Liqa olduğu təqdirdə, turnirin adı hissəsində yalnızca hansı mövsüm və ya hansı dövrə olduğu qeyd edilməlidir. Ümumi liqanın adı isə davamiyyət bölməsindən seçilməlidir (Məsələn, davamiyyət bölməsindən <b>"Peşəkar İntellekt Liqası"</b> seçilir, turnirin adı hissəsinə <b>"2017-2018 mövsümü"</b> yazılır)</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;- Kubok olduğu təqdirdə, davamiyyətə sahib olsa da turnirin adı hissəsində ad tam yazılmalıdır. (Məsələn, davamiyyət bölməsindən <b>"İstiqlal kuboku"</b> seçilir, turnirin adı hissəsinə <b>"V İstiqlal kuboku"</b> yazılır)</p>
            <p>&nbsp;&nbsp;1.4 Ehtiyac olduğu təqdirdə turnir haqqında ətraflı məlumat daxil edilə bilər.</p>
            <p>&nbsp;&nbsp;1.5 Olduğu təqdirdə təşkilatçı qurum və ya qurumlar siyahıdan seçilməli və ya yeni təşkilatçı adı daxil edilməlidir.</p>
            <p>&nbsp;&nbsp;1.6 Olduğu təqdirdə təşkilatçı şəxs və ya şəxslər əlavə edilməlidir. Şəxsin adını qısa şəkildə yazdıqda axtarış nəticəsində müəyyən bir siyahı formalaşır.</p>
            <h4 className="bold">2. Turnirə paket əlavə edilməsi</h4>
            <p>&nbsp;&nbsp;2.1 Paketin adı turnirin adı ilə eynidirsə <b>paketə ad lazım deyil</b>, deyilsə <b>paketə ad lazımdır</b> seçilməlidir</p>
            <p>&nbsp;&nbsp;2.2 Paket haqqında ətraflı məlumata, paketin əvvəlində müəyyən qeydlərə ehtiyac varsa daxil edilməlidir.</p>
            <p>&nbsp;&nbsp;2.3 Paketin oynanıldığı tarix seçilməlidir.</p>
            <p>&nbsp;&nbsp;2.4 Paketin oyun növü seçilməlidir.</p>
            <p>&nbsp;&nbsp;2.5 Paketə uyğun teqlər axtarış ilə seçilməlidir.</p>
            <p>&nbsp;&nbsp;2.6 Paketin redaktorları axtarış ilə seçilməlidir.</p>
            <h4 className="bold">3. Paketin turlar üzrə bölünməsi</h4>
            <p>&nbsp;&nbsp;- Hər bir paket bir və ya bir neçə turdan ibarət ola bilər.</p>
            <p>&nbsp;&nbsp;- Hər bir tur alt-turlardan ibarət ola bilər (Məsələn: Azərbaycan kuboku <b>(Sual paketi)</b> / qrup oyunları <b>(Əsas tur)</b> / 1-ci oyun, 2-ci oyun, 3-cü oyun<b>(Alt turlar)</b>).</p>
            <p>&nbsp;&nbsp;- Bira tura alt tur əlavə etdikdə həmin turun sualları yalnızca alt tura əlavə edilə bilər.</p>
            <p>&nbsp;&nbsp;- Sual əlavə edilmiş bir tura alt tur əlavə edilə bilməz.</p>
            <p>&nbsp;&nbsp;- Turun daxilində suallar və ya mövzuların ardıcıllığı dəyişdirilə bilməyəcəyindən suallar və mövzular düzgün ardıcıllıqla daxilməlidir.</p>
            <h4 className="bold">4. Sual əlavə edilməsi</h4>
            <p>&nbsp;&nbsp;4.1 "Nə? Harada? Nə zaman?" sualı əlavə edilməsi</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.1.1 Sualın tipi seçilməlidir (Adi, Duplet, Blitz)</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Adi sual olduqda sualın mətni, cavabı, varsa mətn və ya foto rekviziti daxil edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Sual duplet və ya blitz olduqda, əgər varsa hər suala ayrı, yoxsa bütün suallara bir rekvizit olduğu seçilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Duplet sual olduqda hər iki sualın mətni, cavabı, varsa mətn və ya foto rekviziti daxil edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Blitz sual olduqda hər üç sualın mətni, cavabı, varsa mətn və ya foto rekviziti daxil edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.1.2 Sualın şərhi daxil edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.1.3 Sualın tərcümədirsə qeyd edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.1.4 Sualöncəsi qeyd varsa qeyd edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.1.5 Sualın sayılma meyarı varsa qeyd edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.1.6 Sualın mənbələri aralarında /-/ qoyulmaqla daxil edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.1.7 Sualın müəllifi və ya müəllifləri seçilməlidir.</p>
            <p>&nbsp;&nbsp;4.2/3 "Xəmsə" Milli İntellektual Oyunu və ya "Fərdi Oyun" mövzusu əlavə edilməsi</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.2/3.1 Mövzunun adı daxil edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.2/3.2 Mövzunun hər bir sualının mətni, cavabı və əgər varsa şərhi (mötərizədə sayılma meyarı) daxil edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.2/3.3 Mövzu tərcümədirsə qeyd edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.2/3.4 Mövzuöncəsi qeyd varsa qeyd edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.2/3.6 Mövzunun mənbələri aralarında /-/ qoyulmaqla daxil edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.2/3.7 Mövzunun müəllifi və ya müəllifləri seçilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.2/3.7 Mövzunun aid olduğu raund/dəyər seçilməlidir.</p>
            <p>&nbsp;&nbsp;4.4 "Breyn Rinq" sualı əlavə edilməsi</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.4.1 Sualın mətni, cavabı və varsa şərhi daxil edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.4.2 Sualın tərcümədirsə qeyd edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.4.3 Sualöncəsi qeyd varsa qeyd edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.4.4 Sualın sayılma meyarı varsa qeyd edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.4.5 Sualın mənbələri aralarında /-/ qoyulmaqla daxil edilməlidir.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;4.4.6 Sualın müəllifi və ya müəllifləri seçilməlidir.</p>
            <h4 className="bold">5. Xüsusi qeydlər</h4>
            <p>&nbsp;&nbsp;- Sualın mətnində və ya rekvizit mətnində xüsusi quruluş varsa, yazı növbəti sətrə keçməlidirsə sətrin sonunda /-/ simvolundan istifadə edilməlidir. <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Məsələn: <pre>&nbsp;&nbsp;&nbsp;Çərşənbə günündə çeşmə başında,<br />
                &nbsp;&nbsp;&nbsp;Gözüm bir alagöz xanıma düşdü,<br />
                &nbsp;&nbsp;&nbsp;Atdı müjgan oxun keçdi sinəmdən,<br />
                &nbsp;&nbsp;&nbsp;Nazu qəmzələri yanıma düşdü<br /></pre>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Şeirin mətnində əvəz edilmiş hərfi tapın.</p>
        </div>
    )
}