import React from "react";
import { Input, InputNumber, Button, message } from "antd";
import sualtap from "../../../const/api";

class Tournament extends React.Component {
  state = {
    authorID: 1,
    author: {},
    authorFetched: false,
  };

  fetchAuthor = async () => {
    await sualtap.get(`authors/${this.state.authorID}`).then((res) => {
      this.setState({
        author: res.data,
        authorFetched: true,
      });
    });
  };

  handleAuthorName = (e) => {
    const tour = { ...this.state.author };
    tour.fullname = e.target.value;
    this.setState({
      author: tour,
    });
  };

  handleUserID = (e) => {
    console.log(e);
    const tour = { ...this.state.author };
    tour.user_ID = e;
    this.setState({
      author: tour,
    });
  };

  handleAuthorID = (e) => {
    this.setState({ authorID: e });
  };

  changeAuthor = async () => {
    await sualtap
      .put(`authors/${this.state.author.id}`, this.state.author)
      .then((res) => {
        message.success("Dəyişiklik edildi");
        this.setState({
          author: {},
          authorFetched: false,
        });
      });
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Müəllif</h2>
        </div>
        <div className="b-add-box">
          <div className="b-add-box-left">
            <div className="b-100">
              <label>Müəllif</label>
              <InputNumber
                value={this.state.authorID}
                onChange={(e) => this.handleAuthorID(e)}
                type="number"
              />
              <Button onClick={this.fetchAuthor} type="primary">
                Müəllifi gətir
              </Button>
            </div>
            {this.state.authorFetched ? (
              <>
                <div className="b-100">
                  <Input
                    value={this.state.author.fullname}
                    onChange={(e) => this.handleAuthorName(e)}
                  />
                  <InputNumber
                    value={this.state.author.user_ID}
                    onChange={(e) => this.handleUserID(e)}
                    type="number"
                  />
                  <Button onClick={this.changeAuthor} type="primary">
                    Dəyişiklik et
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default Tournament;
