import React from "react";
import {
  Input,
  Switch,
  InputNumber,
  Button,
  message,
} from "antd";
import sualtap from "../../../const/api";

class Tournament extends React.Component {
  state = {
    tournamentID: 1,
    tournament: {},
  };

  fetchTournament = async () => {
    await sualtap.get(`tournaments/single?id=${this.state.tournamentID}`).then((res) => {
      this.setState({
        tournament: res.data,
      });
    });
  };

  handleIsLeagueChange = (e) => {
    const tour = { ...this.state.tournament };
    tour.isLeague = this.state.tournament.isLeague ? false : true;
    this.setState({ tournament: tour });
  };

  handleTournamentName = (e) => {
    const tour = { ...this.state.tournament };
    tour.name = e.target.value;
    this.setState({
      tournament: tour,
    });
  };

  handleTournamentID = (e) => {
    this.setState({ tournamentID: e });
  };

  changeTournament = async () => {
    await sualtap
      .put(`tournaments/${this.state.tournament.id}`, this.state.tournament)
      .then((res) => {
        message.success("Dəyişiklik edildi");
        this.setState({
          tournament: {},
        });
      });
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Turnir</h2>
        </div>
        <div className="b-add-box">
          <div className="b-add-box-left">
            <div className="b-100">
              <label>Turnir</label>
              <InputNumber
                value={this.state.tournamentID}
                onChange={(e) => this.handleTournamentID(e)}
                type="number"
              />
              <Button onClick={this.fetchTournament} type="primary">
                Turniri gətir
              </Button>
            </div>
            {this.state.tournament ? (
              <>
                <Switch
                  checkedChildren="Liqa"
                  onChange={this.handleIsLeagueChange}
                  checked={this.state.tournament.isLeague}
                  unCheckedChildren="Kubok"
                  defaultChecked
                />
                <div className="b-100">
                  <Input
                    value={this.state.tournament.name}
                    onChange={(e) => this.handleTournamentName(e)}
                  />
                  <Button onClick={this.changeTournament} type="primary">
                    Dəyişiklik et
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default Tournament;
