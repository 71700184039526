import React from "react";
import { Tabs } from "antd";
import Roles from "./Roles";
import Tags from "./Tags";
import Games from "./Games";

const { TabPane } = Tabs;

class Constants extends React.Component {
  render() {
    return (
      <Tabs defaultActiveKey="1">
        <TabPane tab="İstifadəçi rolları" key="1">
          <Roles />
        </TabPane>
        <TabPane tab="Teqlər" key="2">
          <Tags />
        </TabPane>
        <TabPane tab="Oyun növləri" key="3">
          <Games />
        </TabPane>
      </Tabs>
    );
  }
}

export default Constants;
