import React from "react";
import { Radio, Button, Select, Spin, Switch, message } from "antd";
import "./style/types.css";
import Loader from "../../Loader";
import sualtap from "./../../../../const/api";
import { connect } from "react-redux";
import { loadingOn, loadingOff } from "./../../../../redux/actions";
const { Option } = Select;

class Type2 extends React.Component {
  constructor(props) {
    super(props);
    this.lastAuthorFetchId = 0;
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      allAuthors: [],
      selectedAuthors: [],
      authorsFetching: false,
      theme: "",
      loading: false,
      hasNoteBefore: false,
      isTranslated: false,
      level: 1,
      activePart: 1,
      questions: [
        {
          question: "",
          answer: "",
          comment: "",
          source: "",
          sources: [],
        },
        {
          question: "",
          answer: "",
          comment: "",
          source: "",
          sources: [],
        },
        {
          question: "",
          answer: "",
          comment: "",
          source: "",
          sources: [],
        },
        {
          question: "",
          answer: "",
          comment: "",
          source: "",
          sources: [],
        },
        {
          question: "",
          answer: "",
          comment: "",
          source: "",
          sources: [],
        },
      ],
      noteBefore: "",
      source: "",
      sources: [],
      authors: [],
      khamsa: {
        question: "",
        answer: "",
        comment: "",
        source: "",
        sources: [],
      },
    };
  }

  componentDidMount() {
    this.fetchAuthors();
  }
  fetchAuthors = async () => {
    await sualtap.get("authors").then((res) => {
      this.setState({
        allAuthors: res.data,
      });
    });
  };

  handleTypeChange = (e) => {
    this.setState({ activePart: parseInt(e.target.value) });
  };
  handleLevelChange = (e) => {
    this.setState({ level: parseInt(e.target.value) });
  };
  handleIsTranslatedChange() {
    this.setState({ isTranslated: !this.state.isTranslated });
  }
  renderNoteBefore() {
    return this.state.hasNoteBefore ? (
      <textarea
        onChange={(e) => this.handleNoteBeforeTextChange(e)}
        value={this.state.noteBefore}
        placeholder="Mövzu üçün qeyd"
      ></textarea>
    ) : null;
  }
  handleNoteBeforeChange() {
    this.setState({
      hasNoteBefore: !this.state.hasNoteBefore,
    });
  }

  handleKhamsaSourceChange(e) {
    let khamsa = { ...this.state.khamsa };
    khamsa.source = e.target.value;
    khamsa.sources =
      e.target.value.trim() !== ""
        ? e.target.value
            .trim()
            .split("/-/")
            .map((a) => a.trim())
        : [];
    this.setState({
      khamsa,
    });
  }
  handleSourceChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].source = e.target.value;
    obj[i].sources =
      e.target.value.trim() !== ""
        ? e.target.value
            .trim()
            .split("/-/")
            .map((a) => a.trim())
        : [];
    this.setState({
      questions: obj,
    });
  }
  // Authors search
  filterAuthorsSearch = async (value) => {
    this.lastAuthorFetchId += 1;
    const authorFetchId = this.lastAuthorFetchId;
    this.setState({ authors: [], authorsFetching: true });
    let authores = this.state.allAuthors.filter((a) =>
      a.fullname.toLowerCase().includes(value.toLowerCase())
    );
    if (authorFetchId !== this.lastAuthorFetchId) {
      return;
    }
    this.setState({ authors: authores, authorsFetching: false });
  };
  handleAuthorsSearchChange = (value) => {
    this.setState({
      selectedAuthors: value,
      authors: [],
      authorsFetching: false,
    });
  };

  renderQuestions() {
    let resp = [];
    if (this.state.level < 5) {
      for (let i = 0; i < 5; i++) {
        if (i + 1 === this.state.activePart) {
          resp.push(
            <div key={i} className="single-question">
              <div className="question-number">{i + 1}</div>
              <textarea
                className="green-border"
                placeholder="Sualın mətnini daxil edin"
                onChange={(e) => this.handleQuestionTextChange(e, i)}
                value={this.state.questions[i].question}
              ></textarea>
              <input
                className="green-border"
                type="text"
                value={this.state.questions[i].answer}
                onChange={(e) => this.handleAnswerChange(e, i)}
                placeholder="Sualın cavabı"
              />
              <input
                className="green-border"
                type="text"
                value={this.state.questions[i].comment}
                onChange={(e) => this.handleCommentChange(e, i)}
                placeholder="Sualın şərhi"
              />
              <input
                type="text"
                className="green-border"
                value={this.state.questions[i].source}
                onChange={(e) => this.handleSourceChange(e, i)}
                placeholder="Mənbə"
              />
            </div>
          );
        }
      }
      return resp.map((a) => a);
    } else {
      return (
        <div className="single-question">
          <textarea
            className="green-border"
            placeholder="Sualın mətnini daxil edin"
            onChange={(e) => this.handleKhamsaQuestionChange(e)}
            value={this.state.khamsa.question}
          ></textarea>
          <input
            className="green-border"
            type="text"
            value={this.state.khamsa.answer}
            onChange={(e) => this.handleKhamsaAnswerChange(e)}
            placeholder="Sualın cavabı"
          />
          <input
            className="green-border"
            type="text"
            value={this.state.khamsa.comment}
            onChange={(e) => this.handleKhamsaCommentChange(e)}
            placeholder="Sualın şərhi"
          />
          <input
            type="text"
            value={this.state.khamsa.source}
            onChange={(e) => this.handleKhamsaSourceChange(e)}
            placeholder="Mənbə"
          />
        </div>
      );
    }
  }
  handleKhamsaQuestionChange = (e) => {
    var obj = { ...this.state.khamsa };
    obj.question = e.target.value;
    this.setState({
      khamsa: obj,
    });
  };
  handleKhamsaAnswerChange = (e) => {
    var obj = { ...this.state.khamsa };
    obj.answer = e.target.value;
    this.setState({
      khamsa: obj,
    });
  };
  handleKhamsaCommentChange = (e) => {
    var obj = { ...this.state.khamsa };
    obj.comment = e.target.value;
    this.setState({
      khamsa: obj,
    });
  };

  handleQuestionTextChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].question = e.target.value;
    this.setState({
      questions: obj,
    });
  }
  handleCommentChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].comment = e.target.value;
    this.setState({
      questions: obj,
    });
  }
  handleThemeChange = (e) => {
    this.setState({ theme: e.target.value });
  };
  handleAnswerChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].answer = e.target.value;
    this.setState({
      questions: obj,
    });
  }
  handleNoteBeforeTextChange(e) {
    this.setState({
      noteBefore: e.target.value,
    });
  }
  submitQuestion = () => {
    var obj = {
      theme: this.state.theme,
      information:
        this.state.noteBefore.trim().length !== 0
          ? this.state.noteBefore.trim()
          : null,
      raund: this.state.level,
      values: this.state.questions.map((ques) => {
        return {
          ...ques,
          comment: ques.comment.trim().length ? ques.comment.trim() : null,
        };
      }),
      sources: this.state.sources,
      authors: this.state.selectedAuthors.map((k) => parseInt(k.key)),
      phase: parseInt(this.props.params.id),
      isNotOriginal: this.state.isTranslated,
    };
    if (this.state.level === 5) {
      obj.values = [
        {
          ...this.state.khamsa,
          comment: this.state.khamsa.comment.trim().length
            ? this.state.khamsa.comment.trim()
            : null,
        },
      ];
    }
    if (this.validateQuestion()) {
      this.props.loadingOn();
      sualtap.post("questions/khamsa", JSON.stringify(obj)).then((res) => {
        this.setState(this.getInitialState());
        this.props.loadingOff();
        this.fetchAuthors();
        this.props.fetch();
      });
    }
  };
  isNullOrEmpty(str) {
    return str === null || str.trim().length === 0 ? true : false;
  }
  validateQuestion = () => {
    let validated = true;
    if (this.state.theme.trim().length === 0) {
      message.warning("Mövzunun adını təyin edin");
      validated = false;
    }
    if (this.state.level < 5) {
      for (let i = 0; i < 5; i++) {
        if (this.state.questions[i].question.length === 0) {
          if (i === 2 || i === 3) {
            message.warning(`${i + 1}-cü sualın mətnini daxil edin`);
            validated = false;
          } else {
            message.warning(`${i + 1}-ci sualın mətnini daxil edin`);
            validated = false;
          }
        }
        if (this.state.questions[i].answer.length === 0) {
          if (i === 2 || i === 3) {
            message.warning(`${i + 1}-cü sualın cavabını daxil edin`);
            validated = false;
          } else {
            message.warning(`${i + 1}-ci sualın cavabını daxil edin`);
            validated = false;
          }
        }
      }
    } else {
      if (this.state.khamsa.question.trim().length === 0) {
        message.warning("Sualın mətnini daxil edin");
        validated = false;
      }
      if (this.state.khamsa.answer.trim().length === 0) {
        message.warning("Sualın cavabını daxil edin");
        validated = false;
      }
    }
    return validated;
  };

  renderRaund() {
    switch (this.state.level) {
      case 1:
        return (
          <h2>
            <b>Bəlli raund</b>
          </h2>
        );
      case 2:
        return (
          <h2>
            <b>Şərtli raund</b>
          </h2>
        );
      case 3:
        return (
          <h2>
            <b>Sirli raund</b>
          </h2>
        );
      case 4:
        return (
          <h2>
            <b>Fərdi raund</b>
          </h2>
        );
      case 5:
        return (
          <h2>
            <b>Xəmsə raundu</b>
          </h2>
        );
      default:
        return (
          <h2>
            <b>Bəlli raund</b>
          </h2>
        );
    }
  }

  render() {
    return (
      <>
        {this.state.loading ? <Loader /> : null}
        <div className="b-add-box">
          <div className="b-add-box-left bg-white">
            <div className="b-type-radios-5">
              {this.state.level < 5 ? (
                <Radio.Group
                  defaultValue={this.state.activePart.toString()}
                  value={this.state.activePart.toString()}
                  buttonStyle="solid"
                  onChange={this.handleTypeChange}
                >
                  <Radio.Button value="1">Sual 1</Radio.Button>
                  <Radio.Button value="2">Sual 2</Radio.Button>
                  <Radio.Button value="3">Sual 3</Radio.Button>
                  <Radio.Button value="4">Sual 4</Radio.Button>
                  <Radio.Button value="5">Sual 5</Radio.Button>
                </Radio.Group>
              ) : null}
            </div>
            {!this.props.isNotOriginal ? (
              <Switch
                className="mt-10"
                checkedChildren="Mövzu tərcümədir"
                onChange={() => this.handleIsTranslatedChange()}
                unCheckedChildren="Mövzu originaldır"
                checked={this.state.isTranslated}
              />
            ) : null}
            <Switch
              className="mt-10"
              checkedChildren="Mövzu üçün qeyd var"
              onChange={() => this.handleNoteBeforeChange()}
              unCheckedChildren="Mövzu üçün qeyd yoxdur"
              checked={this.state.hasNoteBefore}
            />
            {this.renderNoteBefore()}
            <div className="question-details">
              <input
                type="text"
                value={this.state.theme}
                onChange={(e) => this.handleThemeChange(e)}
                placeholder="Mövzunun adı"
              />
            </div>
            {this.renderQuestions()}
            <Select
              mode="multiple"
              labelInValue
              value={this.state.selectedAuthors}
              placeholder="Müəllifləri seç"
              notFoundContent={
                this.state.authorsFetching ? <Spin size="small" /> : null
              }
              filterOption={false}
              onSearch={this.filterAuthorsSearch}
              onChange={this.handleAuthorsSearchChange}
              style={{ width: "100%" }}
            >
              {this.state.authors.map((a) => (
                <Option key={a.id}>{a.fullname}</Option>
              ))}
            </Select>
            <div className="b-level-radios mt-10">
              <Radio.Group
                defaultValue={this.state.level.toString()}
                value={this.state.level.toString()}
                buttonStyle="solid"
                onChange={this.handleLevelChange}
              >
                <Radio.Button value="1">Bəlli</Radio.Button>
                <Radio.Button value="2">Şərtli</Radio.Button>
                <Radio.Button value="3">Sirli</Radio.Button>
                <Radio.Button value="4">Fərdi</Radio.Button>
                <Radio.Button value="5">Xəmsə</Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <div className="b-add-box-right bg-white">
            <h3>
              <b>Mövzu nömrə: {this.props.number}</b>
            </h3>
            {this.renderRaund()}
            {this.state.theme ? (
              <h3>
                <b>Mövzu:</b> {this.state.theme}
              </h3>
            ) : (
              ""
            )}
            {this.state.noteBefore.trim() ? (
              <div className="b-question-item b-answer">
                <span>Mövzu üçün qeyd: </span>
                {this.state.noteBefore}
              </div>
            ) : null}
            {this.state.level > 4 ? (
              <div className="b-question-content">
                <div className="b-question-item b-answer">
                  <span>Sual: </span>
                  {this.state.khamsa.question}
                </div>
                <div className="b-question-item b-answer">
                  <span>Cavab: </span>
                  {this.state.khamsa.answer}
                </div>
                {this.state.khamsa.comment ? (
                  <div className="b-question-item b-answer">
                    <span>Şərh: </span>
                    {this.state.khamsa.comment}
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                {this.state.questions.map((q) => {
                  const key = this.state.questions.indexOf(q) + 1;
                  const main = q.question.split("/-/");
                  let towrite = main.map((m, index) => {
                    return (
                      <span key={index}>
                        {index ? <span>&nbsp;&nbsp;</span> : null}
                        {m}
                        <br />
                      </span>
                    );
                  });

                  return (
                    <div key={key} className="b-question-content">
                      <p>
                        {key}.&nbsp;
                        {towrite}
                      </p>
                      <div className="b-question-item b-answer">
                        <span>Cavab: </span>
                        {q.answer}
                      </div>
                      {q.comment ? (
                        <div className="b-question-item b-answer">
                          <span>Şərh: </span>
                          {q.comment}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </>
            )}
            {this.state.sources.length > 0 ? (
              <div className="b-question-item b-answer">
                <p>
                  <span>Mənbə:&nbsp;</span>
                  {/* {this.state.source} */}
                  {this.state.sources.map((q, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index > 0 ? (
                          <>
                            <br />{" "}
                            <span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </>
                        ) : null}
                        {index + 1 + ". " + q}
                      </React.Fragment>
                    );
                  })}
                </p>
              </div>
            ) : null}
            {this.state.selectedAuthors.length > 0 ? (
              <div className="b-question-item b-answer">
                <span>
                  {this.state.selectedAuthors.length > 1
                    ? "Müəlliflər: "
                    : "Müəllif: "}
                </span>
                {this.state.selectedAuthors.map((aut, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && index < this.state.selectedAuthors.length
                      ? ", "
                      : ""}
                    {aut.label}
                  </React.Fragment>
                ))}
              </div>
            ) : null}
            <div className="b-question-item mt-10">
              <Button onClick={this.submitQuestion} type="primary">
                Əlavə et
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { loadingOn, loadingOff })(Type2);
