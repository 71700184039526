import React from "react";
import { Tabs } from "antd";
import "./style/style.css";
import Package from "./Package";
import Tournament from "./Tournament";
import Phase from "./Phase";
import Author from "./Author";
const { TabPane } = Tabs;

class Update extends React.Component {
  render() {
    return (
      <div className="tabs-panel">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Sual" key="1"></TabPane>
          <TabPane tab="Mövzu" key="2"></TabPane>
          <TabPane tab="Paket" key="3">
            <Package />
          </TabPane>
          <TabPane tab="Turnir" key="4">
            <Tournament />
          </TabPane>
          <TabPane tab="Tur" key="5">
            <Phase />
          </TabPane>
          <TabPane tab="Müəllif" key="6">
            <Author />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Update;
