import React, { Component } from "react";
import { Menu, Icon, Button } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./style/LeftMenu.css";

class LeftMenu extends Component {
  state = {
    mode: "inline",
    theme: "light",
    collapsed: false,
    openKeys: []
  };
  rootSubmenuKeys = ["sub1", "sub2"];

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(
      key => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      });
    }
  };

  render() {
    return (
      <div
        style={{
          col: 2,
          float: "left",
          width: this.state.collapsed ? 80 : 300,
          minHeight: "100vh",
          backgroundColor: "white",
          zIndex: 8
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: this.state.collapsed ? "center" : "flex-end",
            padding: this.state.collapsed ? "15px 0px" : "15px 10px 15px 0px"
          }}
        >
          <Button type="primary" onClick={this.toggleCollapsed}>
            <Icon type={this.state.collapsed ? "menu-unfold" : "menu-fold"} />
          </Button>
        </div>
        <Menu
          openKeys={this.state.openKeys}
          inlineCollapsed={this.state.collapsed}
          mode={this.state.mode}
          theme={this.state.theme}
          onOpenChange={this.onOpenChange}
        >
          {this.props.user >= 3 ? (
            <Menu.Item key="1">
              <Link to="/">
                <Icon type="fund" />
                <span>Statistika</span>
              </Link>
            </Menu.Item>
          ) : null}
          {this.props.user >= 5 ? (
            <Menu.Item key="2">
              <Link to="/users">
                <Icon type="user" />
                <span>İstifadəçilər</span>
              </Link>
            </Menu.Item>
          ) : null}
          {/* {this.props.user >= 5 ? (
            <Menu.Item key="3">
              <Link to="/teams">
                <Icon type="team" />
                <span>Komandalar</span>
              </Link>
            </Menu.Item>
          ) : null} */}
          {/* {this.props.user >= 4 ? (
            <Menu.Item key="6">
              <Link to="/comments">
                <Icon type="laptop" />
                <span>Şərhlər</span>
              </Link>
            </Menu.Item>
          ) : null} */}
          {this.props.user >= 4 ? (
            <Menu.Item key="11">
              <Link to="/organizations">
                <Icon type="eye" />
                <span>Təşkilatlar</span>
              </Link>
            </Menu.Item>
          ) : null}
          {this.props.user >= 3 ? (
            <Menu.Item key="7">
              <Link to="/tournaments">
                <Icon type="bulb" />
                <span>Turnirlər</span>
              </Link>
            </Menu.Item>
          ) : null}
          <Menu.Item key="30">
            <Link to="/rules">
              <Icon type="bulb" />
              <span>Qaydalar</span>
            </Link>
          </Menu.Item>
          {this.props.user >= 4 ? (
            <Menu.Item key="8">
              <Link to="/claims">
                <Icon type="mail" />
                <span>İddialar / Mesajlar</span>
              </Link>
            </Menu.Item>
          ) : null}
          {/* {this.props.user >= 4 ? (
            <Menu.Item key="9">
              <Link to="/authors">
                <Icon type="bug" />
                <span>Müəllif / Redaktorlar</span>
              </Link>
            </Menu.Item>
          ) : null} */}
          <Menu.Item key="29">
            <Link to="/my">
              <Icon type="bug" />
              <span>Paketlərim</span>
            </Link>
          </Menu.Item>
          {this.props.user === 5 ? (
            <Menu.Item key="61">
              <Link to="/constants">
                <Icon type="setting" />
                <span>Sabitlər</span>
              </Link>
            </Menu.Item>
          ) : null}
          {this.props.user === 5 ? (
            <Menu.Item key="62">
              <Link to="/update">
                <Icon type="setting" />
                <span>Düzəliş</span>
              </Link>
            </Menu.Item>
          ) : null}
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user: user.data.role
  };
};
export default connect(mapStateToProps)(LeftMenu);
