import axios from "axios";
import siteUrl from "./const";

export const sualtap = axios.create({
  baseURL: `${siteUrl}api`,
  headers: {
    "Content-Type": "application/json",
  },
});

sualtap.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = localStorage.getItem("sualtapUser")
      ? "Bearer " + JSON.parse(localStorage.getItem("sualtapUser")).token
      : null;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default sualtap;
