import React from "react";
import { Table, Button, message } from "antd";
import sualtap from "../../const/api";

const columns = [
  {
    title: "ID",
    dataIndex: "id"
  },
  {
    title: "Name",
    dataIndex: "name"
  }
];

class Tags extends React.Component {
  state = {
    tags: [],
    newTagName: ""
  };

  fetchTags() {
    sualtap.get("tags").then(res =>
      this.setState({
        tags: res.data.map(re => {
          return {
            key: re.id,
            ...re
          };
        })
      })
    );
  }
  componentDidMount() {
    this.fetchTags();
  }
  handleNameChange = e => {
    this.setState({
      newTagName: e.target.value
    });
  };
  addTag = async e => {
    e.preventDefault();
    if (!this.state.newTagName.trim().length) {
      message.warning("Teqin adını daxil edin");
    } else {
      await sualtap.post(
        "tags",
        JSON.stringify({
          name: this.state.newTagName
        })
      );
      message.success("Teq əlavə edildi");
      this.fetchTags();
      this.setState({ newTagName: "" });
    }
  };

  render() {
    return !this.props.type ? (
      <>
        <div className="b-page-top">
          <h2>Teqlər</h2>
        </div>
        <div className="b-add-box">
          <div className="b-add-box-left">
            <Table
              columns={columns}
              dataSource={this.state.tags}
              size="middle"
            />
          </div>
          <div className="b-add-box-right">
            <form onSubmit={this.addTag}>
              <label>Teqin adı</label>
              <input
                value={this.state.newTagName}
                onChange={this.handleNameChange}
                className="ant-input"
                type="text"
              />
              <div className="fl w-100">
                <Button htmlType="submit" type="primary">
                  Əlavə et
                </Button>
              </div>
            </form>
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="b-page-top">
          <h2>Teqlər</h2>
        </div>
        <div className="b-add-box">
          <Table className="w-100" columns={columns} dataSource={this.state.tags} size="middle" />
        </div>
      </>
    );
  }
}

export default Tags;
