import React from "react";
import sualtap from "./../../../const/api";
import { Table, Button, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { loadingOn, loadingOff } from "./../../../redux/actions";

const qcolumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Sual",
    dataIndex: "question",
    key: "question"
  },
  {
    title: "Cavab",
    dataIndex: "answer",
    key: "answer"
  },
  {
    title: "Şərh",
    dataIndex: "comment",
    key: "comment"
  },
  {
    title: "Tip",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Edit",
    dataIndex: "edit",
    key: "edit"
  },
  {
    title: "Sil",
    dataIndex: "sil",
    key: "sil"
  }
];
const tcolumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Məlumat",
    dataIndex: "information",
    key: "information"
  },
  {
    title: "Raund",
    dataIndex: "raund",
    key: "raund"
  },
  {
    title: "Edit",
    dataIndex: "edit",
    key: "edit"
  },
  {
    title: "Sil",
    dataIndex: "sil",
    key: "sil"
  }
];
function cancel() {
  console.log("Cancelll");
}

class See extends React.Component {
  state = {
    questions: [],
    themes: [],
    theme: false,
    name: ""
  };

  deleteItem = async id => {
    this.props.loadingOn();
    if (this.state.theme) {
      await sualtap
        .post(`questions/delete/theme?id=${id}`)
        .then(res => console.log(res));
      this.fetchByPhase();
    } else {
      await sualtap
        .post(`questions/delete/nhn?id=${id}`)
        .then(res => console.log(res));
      this.fetchByPhase();
    }
  };

  renderType(t) {
    switch (t) {
      case 1:
        return "Adi";
      case 2:
        return "Duplet";
      case 3:
        return "Blitz";
      default:
        return "Adi";
    }
  }

  fetchByPhase = async () => {
    this.props.loadingOn();
    const themes = [];
    const questions = [];
    await sualtap
      .get(`questions/byphase?phase=${this.props.params.id}`)
      .then(res => {
        console.log(res.data);
        this.setState({
          name: res.data.name,
          theme: res.data.with_Theme
        });
        if (res.data.with_Theme) {
          res.data.themes.map(({ name, id, information, raund }) =>
            themes.push({
              name,
              key: id,
              id,
              information,
              raund,
              edit: <Link to={`/edit/theme/${id}`}>Düzəliş</Link>,
              sil: (
                <Popconfirm
                  title="Silmək istədiyinizdən əminsiniz?"
                  onConfirm={() => this.deleteItem(id)}
                  onCancel={cancel}
                  okText="Bəli"
                  cancelText="Xeyr"
                >
                  <Button>Sil</Button>
                </Popconfirm>
              )
              // see: this.props.user >= 4 ? <Link to={`/Tournament/${id}/Packages`}>Paketlərə bax</Link> : null
            })
          );
          this.setState({
            themes: themes.reverse()
          });
        } else {
          res.data.questions.map(({ id, question, answer, comment, qtype }) =>
            questions.push({
              key: id,
              id,
              question,
              answer,
              comment,
              type: this.renderType(qtype),
              edit: <Link to={`/edit/question/${id}`}>Düzəliş</Link>,
              sil: (
                <Popconfirm
                  title="Silmək istədiyinizdən əminsiniz?"
                  onConfirm={() => this.deleteItem(id)}
                  onCancel={cancel}
                  okText="Bəli"
                  cancelText="Xeyr"
                >
                  <Button>Sil</Button>
                </Popconfirm>
              )
              // see: this.props.user >= 4 ? <Link to={`/Tournament/${id}/Packages`}>Paketlərə bax</Link> : null
            })
          );
          this.setState({
            questions: questions.reverse()
          });
        }
      });
    setTimeout(() => {
      this.props.loadingOff();
    }, 500);
  };

  componentDidMount = () => {
    this.fetchByPhase();
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>{this.state.name}</h2>
        </div>
        <div className="floater">
          {this.state.theme ? (
            <Table dataSource={this.state.themes} columns={tcolumns} />
          ) : (
            <Table dataSource={this.state.questions} columns={qcolumns} />
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ user }) => {
  return {
    user: user.data.role
  };
};

export default connect(mapStateToProps, { loadingOn, loadingOff })(See);
