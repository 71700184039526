import React from "react";
import sualtap from "../../../const/api";
import { Input, Select, Spin, InputNumber, Button, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { connect } from "react-redux";
import history from "../../../const/history";
import { loadingOff, loadingOn } from "../../../redux/actions";
const { Option } = Select;

class Tedit extends React.Component {
  constructor(props) {
    super(props);
    this.lastAuthorFetchId = 0;
    this.state = {
      theId: 1,
      theme: {
        questions: [],
      },
      allAuthors: [],
      selectedAuthors: [],
      authors: [],
      authorsFetching: false,
    };
  }
  getTheme = async (id) => {
    await sualtap.get(`themes/${this.state.theId}`).then((res) => {
      let arr = [];
      res.data.author_Ofs.forEach((el) => {
        const label = this.state.allAuthors.find((l) => l.id === el.author_ID)
          .fullname;
        arr.push({ key: el.author_ID.toString(), label });
      });
      this.setState({ theme: res.data, selectedAuthors: arr });
    });
  };

  // Authors search
  filterAuthorsSearch = async (value) => {
    this.lastAuthorFetchId += 1;
    const authorFetchId = this.lastAuthorFetchId;
    this.setState({ authors: [], authorsFetching: true });
    let authores = this.state.allAuthors.filter((a) =>
      a.fullname.toLowerCase().includes(value.toLowerCase())
    );
    if (authorFetchId !== this.lastAuthorFetchId) {
      return;
    }
    this.setState({ authors: authores, authorsFetching: false });
  };
  handleAuthorsSearchChange = (value) => {
    this.setState({
      selectedAuthors: value,
      authors: [],
      authorsFetching: false,
    });
  };
  fetchAuthors = async () => {
    await sualtap.get("authors").then((res) => {
      this.setState({
        allAuthors: res.data,
      });
    });
  };

  //text, comment, noteBefore, answer

  handleIdChange = (e) => this.setState({ theId: e });

  //theme
  handleNoteBeforeChange = (e) => {
    this.setState({
      theme: { ...this.state.theme, noteBefore: e.target.value },
    });
  };
  handleThemeNameChange = (e) => {
    this.setState({
      theme: { ...this.state.theme, name: e.target.value },
    });
  };
  handleRaundChange = (e) =>
    this.setState({ theme: { ...this.state.theme, raund: e } });
  handlePhaseChange = (e) =>
    this.setState({ theme: { ...this.state.theme, phase_ID: e } });
  //theme ends

  //question
  handleTextChange = (e, index) => {
    let theme = { ...this.state.theme };
    theme.questions[index].text = e.target.value;
    this.setState({
      theme,
    });
  };
  handleAnswerChange = (e, index) => {
    let theme = { ...this.state.theme };
    theme.questions[index].answer = e.target.value;
    this.setState({
      theme,
    });
  };
  handleCommentChange = (e, index) => {
    let theme = { ...this.state.theme };
    theme.questions[index].comment = e.target.value;
    this.setState({
      theme,
    });
  };
  handleConsideredChange = (e, index) => {
    let theme = { ...this.state.theme };
    theme.questions[index].considered = e.target.value;
    this.setState({
      theme,
    });
  };
  //question ends

  changeQuestion = async (e, index) => {
    let obj = this.state.theme.questions[index];
    await sualtap
      .put(`questions/${obj.id}`, obj)
      .then((res) => message.success("Sualda düzəliş edildi"));
  };
  changeTheme = async () => {
    await sualtap
      .put(`themes/${this.state.theId}`, this.state.theme)
      .then((res) => message.success("Mövzuda düzəliş edildi"));
  };

  componentDidMount = async () => {
    this.props.loadingOn();
    await this.fetchAuthors();
    if (!this.props.head) {
      let phase = 0;
      await sualtap.get(`themes/${this.props.params.id}`).then((res) => {
        phase = res.data.phase_ID;
        let arr = [];
        res.data.author_Ofs.forEach((el) => {
          const label = this.state.allAuthors.find((l) => l.id === el.author_ID)
            .fullname;
          arr.push({ key: el.id.toString(), label });
        });
        this.setState({
          theme: res.data,
          selectedAuthors: arr,
          theId: this.props.params.id,
        });
      });
      await sualtap.get(`questions/byphase?phase=${phase}`).then((res) => {
        if (res.data.user !== this.props.user.user_ID) {
          history.push("/my");
        }
      });
      this.props.loadingOff();
    } else {
      this.props.loadingOff();
    }
  };

  fixAuthors = async () => {
    let selected = [];
    this.state.selectedAuthors.forEach((el) => {
      selected.push(+el.key);
    });
    let old = [];
    this.state.theme.author_Ofs.forEach((el) => {
      old.push(el.author_ID);
    });
    //keep - 1    //update - 2    //delete - 3    //new - 4
    let copySelected = [...selected];
    let copyOld = [...old];
    let process = [];
    copySelected.forEach((el) => {
      if (copyOld.includes(el)) {
        process.push({ action: 1, id: el, val: null });
        copySelected = copySelected.filter((a) => a !== el);
        copyOld = copyOld.filter((a) => a !== el);
      }
    });
    copyOld.forEach((el) => {
      if (copySelected.length) {
        process.push({ action: 2, id: el, val: copySelected[0] });
        copySelected.shift();
        copyOld = copyOld.filter((a) => a !== el);
      } else {
        process.push({ action: 3, id: el, val: null });
      }
    });
    copySelected.forEach((el) => {
      process.push({ action: 4, id: el, val: null });
    });
    let success = true;
    process.forEach((el) => {
      switch (el.action) {
        case 1:
          return;
        case 2:
          let author_update = this.state.theme.author_Ofs.filter(
            (ao) => ao.author_ID === el.id
          )[0];
          author_update.author_ID = el.val;
          sualtap
            .put(`author_of/${author_update.id}`, author_update)
            .then((error) => (success = false));
          break;
        case 3:
          let author_delete = this.state.theme.author_Ofs.filter(
            (ao) => ao.author_ID === el.id
          )[0];
          sualtap
            .delete(`author_of/${author_delete.id}`)
            .then((error) => (success = false));
          break;
        case 4:
          let author_new = {
            author_ID: el.id,
            theme_ID: this.state.theId,
            question_ID: null,
          };
          sualtap
            .post("author_of", author_new)
            .then((error) => (success = false));
          break;
        default:
          break;
      }
    });
    if (success) {
      message.success("Müəlliflərdə düzəliş edildi");
    }
  };

  render() {
    return (
      <div className="editPage">
        {this.props.head ? (
          <>
            <div className="edit-part" style={{ width: 300 }}>
              <InputNumber
                type="number"
                value={this.state.theId}
                min={1}
                onChange={(e) => this.handleIdChange(e)}
              />
              <Button onClick={this.getTheme} type="primary">
                Axtar
              </Button>
            </div>
          </>
        ) : null}
        {this.state.theme.questions.length !== 0 ? (
          <>
            <div className="theme-edit-parts">
              <label>Mövzuöncəsi qeyd</label>
              <div className="label-field">
                <Input
                  onChange={(e) => this.handleNoteBeforeChange(e)}
                  value={this.state.theme.noteBefore}
                />
              </div>
              <label>Mövzunun adı</label>
              <div className="label-field">
                <Input
                  onChange={(e) => this.handleThemeNameChange(e)}
                  value={this.state.theme.name}
                />
              </div>
              <label>Raund</label>
              <div className="label-field">
                <InputNumber
                  onChange={(e) => this.handleRaundChange(e)}
                  value={this.state.theme.raund}
                />
              </div>
              {this.props.user.role === 5 ? (
                <>
                  <label>Tur</label>
                  <div className="label-field">
                    <InputNumber
                      onChange={(e) => this.handlePhaseChange(e)}
                      value={this.state.theme.phase_ID}
                    />
                  </div>
                </>
              ) : null}
              <Button type="primary" onClick={this.changeTheme}>
                Dəyiş
              </Button>
              <div className="label-field">
                <Select
                  mode="multiple"
                  labelInValue
                  value={this.state.selectedAuthors}
                  placeholder="Müəllifləri seç"
                  notFoundContent={
                    this.state.authorsFetching ? <Spin size="small" /> : null
                  }
                  filterOption={false}
                  onSearch={this.filterAuthorsSearch}
                  onChange={this.handleAuthorsSearchChange}
                  style={{ width: "100%" }}
                >
                  {this.state.authors.map((a) => (
                    <Option key={a.id}>{a.fullname}</Option>
                  ))}
                </Select>
                <Button type="primary" onClick={this.fixAuthors}>
                  Müəllifləri düzəlt{" "}
                </Button>
              </div>
            </div>
            <div>
              {this.state.theme.questions.map((q, index) => {
                return (
                  <div key={index} className="theme-edit-parts">
                    <label>{index + 1}. Sualın mətni</label>
                    <div className="label-field">
                      <TextArea
                        onChange={(e) => this.handleTextChange(e, index)}
                        value={q.text}
                        rows={3}
                      />
                    </div>
                    <div className="two-items">
                      <div className="two-item">
                        <label>Sualın cavabı</label>
                        <div className="label-field">
                          <Input
                            onChange={(e) => this.handleAnswerChange(e, index)}
                            value={q.answer}
                          />
                        </div>
                      </div>
                      <div className="two-item">
                        <label>Sayılma meyarı</label>
                        <div className="label-field">
                          <Input
                            onChange={(e) =>
                              this.handleConsideredChange(e, index)
                            }
                            value={q.considered}
                          />
                        </div>
                      </div>
                    </div>
                    <label>Sualın şərhi</label>
                    <div className="label-field">
                      <TextArea
                        onChange={(e) => this.handleCommentChange(e, index)}
                        value={q.comment}
                        rows={2}
                      />
                    </div>
                    <Button
                      type="primary"
                      onClick={(e) => this.changeQuestion(e, index)}
                    >
                      Dəyiş {index + 1}
                    </Button>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user: user.data,
  };
};

export default connect(mapStateToProps, { loadingOn, loadingOff })(Tedit);
