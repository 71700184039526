import React from "react";
import { Table } from "antd";
import sualtap from "./../../const/api";

const columns = [
  {
    title: "Adı",
    dataIndex: "name",
    key: "name",
    render: text => <a href="/">{text}</a>
  },
  {
    title: "Soyad",
    dataIndex: "surname",
    key: "surname",
    render: text => <span>{text}</span>
  },
  {
    title: "İstifadəçi adı",
    dataIndex: "username",
    key: "username"
  },
  {
    title: "Telefon",
    dataIndex: "phone",
    key: "phone"
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email"
  }
];

class Teams extends React.Component {
  state = {
    teams: []
  };

  fetchTeams() {
    sualtap.get("teams").then(res =>
      this.setState({
        teams: res.data
      })
    );
  }

  componentDidMount() {
    this.fetchTeams();
  }

  render() {
    const dataSource = this.state.teams.map(team => ({
      key: team.id,
      ...team
    }));

    return (
      <React.Fragment>
        <div className="b-page-top">
          <h2>Komandalar</h2>
        </div>
        <Table columns={columns} dataSource={dataSource} />
      </React.Fragment>
    );
  }
}

export default Teams;
