import React from "react";
import { Input, Upload, Button, Icon, message } from "antd";
import sualtap from "./../../../const/api";
import { connect } from "react-redux";
import Loader from "./../Loader";
import history from './../../../const/history';
const { TextArea } = Input;

const props2 = {
    listType: "picture",
    className: "upload-list-inline",
    accept: ".png,.jpg,.jpeg,.svg"
};

class Organization extends React.Component {
    state = {
        name: "",
        information: null,
        city: "",
        phone: "",
        email: null,
        website: null,
        whatsapp: null,
        facebook: "",
        instagram: null,
        short_name: "",
        logo: null,
        isLoading: false
    };

    validateInputs = () => {
        const {
            name,
            city,
            // phone,
            // email,
            short_name,
            logo
            // ,
            // information
        } = this.state;
        !name && message.warning("Təşkilatın adını daxil edin");
        // information.length < 30 &&
        //     message.warning("Təşkilat haqqında ətraflı məlumat daxil edin");
        !city &&
            message.warning(
                "Təşkilatın fəaliyyət göstərdiyi mərkəzi şəhəri daxil edin"
            );
        // !phone && message.warning("Təşkilatın əlaqə nömrəsini qeyd edin");
        // !email && message.warning("Təşkilatın email ünvanını qeyd edin");
        !short_name && message.warning("Təşkilatın qısa adını qeyd edin");
        !logo && message.warning("Təşkilatın loqosunu daxil edin");
        if (
            name &&
            city &&
            // phone &&
            // email &&
            short_name &&
            logo
            // information.length > 30
        ) {
            return true;
        } else {
            return false;
        }
    };

    handleInputChange = (e, type) => {
        const obj = { ...this.state };
        obj[type] = e.target.value;
        this.setState({ ...obj });
    };

    uploadImage = e => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + e.file.name;
        form_data.append("image", e.file, filename);
        sualtap
            .post("organizations/logo", form_data, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
            .then(res => {
                this.setState({
                    logo: res.data
                });
            })
            .catch(err => console.log(err));
    };

    addOrganization = async () => {
        if (this.validateInputs()) {
            this.setState({ isLoading: true })
            await sualtap
                .post("organizations", { ...this.state, user: this.props.user })
                .then(res => console.log(res.data))
                .catch(err => console.log(err));
            this.setState({
                isLoading: false
            })
            history.push("/organizations");
        }
    };

    render() {
        const loader = this.state.isLoading ? <Loader /> : null;
        return (
            <React.Fragment>
                {loader}
                <div className="b-page-top">
                    <h2>Təşkilat əlavə et</h2>
                </div>
                <div className="b-add-box add-organization">
                    <div className="b-add-box-left">
                        <label>
                            Ad <span className="red">*</span>
                        </label>
                        <Input
                            value={this.state.name}
                            onChange={e => this.handleInputChange(e, "name")}
                        />
                        <label>
                            Məlumat <span className="red"></span>
                        </label>
                        <TextArea
                            onChange={e => this.handleInputChange(e, "information")}
                        />
                        <div className="block-of-two">
                            <div className="block-of-two-item">
                                <label>
                                    Email <span className="red"></span>
                                </label>
                                <Input
                                    value={this.state.email}
                                    onChange={e => this.handleInputChange(e, "email")}
                                />
                            </div>
                            <div className="block-of-two-item">
                                <label>
                                    Qısa ad <span className="red">*</span>
                                </label>
                                <Input
                                    value={this.state.short_name}
                                    onChange={e => this.handleInputChange(e, "short_name")}
                                />
                            </div>
                        </div>
                        <div className="block-of-two">
                            <div className="block-of-two-item">
                                <label>
                                    Telefon <span className="red"></span>
                                </label>
                                <Input
                                    value={this.state.phone}
                                    onChange={e => this.handleInputChange(e, "phone")}
                                />
                            </div>
                            <div className="block-of-two-item">
                                <label>Whatsapp</label>
                                <Input
                                    value={this.state.whatsapp}
                                    onChange={e => this.handleInputChange(e, "whatsapp")}
                                />
                            </div>
                        </div>
                        <div className="block-of-two">
                            <div className="block-of-two-item">
                                <label>
                                    Facebook <span className="red"></span>
                                </label>
                                <Input
                                    value={this.state.facebook}
                                    onChange={e => this.handleInputChange(e, "facebook")}
                                />
                            </div>
                            <div className="block-of-two-item">
                                <label>Instagram</label>
                                <Input
                                    value={this.state.instagram}
                                    onChange={e => this.handleInputChange(e, "instagram")}
                                />
                            </div>
                        </div>
                        <div className="block-of-two">
                            <div className="block-of-two-item">
                                <label>
                                    Şəhər <span className="red">*</span>
                                </label>
                                <Input
                                    value={this.state.city}
                                    onChange={e => this.handleInputChange(e, "city")}
                                />
                            </div>
                            <div className="block-of-two-item media">
                                <label>Loqo</label>
                                <Upload {...props2} customRequest={e => this.uploadImage(e)}>
                                    <Button>
                                        <Icon type="upload" /> Yüklə
                                </Button>
                                </Upload>
                            </div>
                        </div>
                    </div>
                    <div className="b-add-box-right">
                        {
                            this.state.name && (
                                <div className="b-section">
                                    <label>Ad</label>
                                    <p>{this.state.name}</p>
                                </div>
                            )
                        }
                        {
                            this.state.short_name && (
                                <div className="b-section">
                                    <label>Qısa ad</label>
                                    <p>{this.state.short_name}</p>
                                </div>
                            )
                        }
                        {
                            this.state.city && (
                                <div className="b-section">
                                    <label>Şəhər</label>
                                    <p>{this.state.city}</p>
                                </div>
                            )
                        }
                        {
                            this.state.information && (
                                <div className="b-section">
                                    <label>Məlumat</label>
                                    <p>{this.state.information}</p>
                                </div>
                            )
                        }
                        {
                            this.state.phone && (
                                <div className="b-section">
                                    <label>Telefon</label>
                                    <p>{this.state.phone}</p>
                                </div>
                            )
                        }
                        {
                            this.state.whatsapp && (
                                <div className="b-section">
                                    <label>Whatsapp</label>
                                    <p>{this.state.whatsapp}</p>
                                </div>
                            )
                        }
                        {
                            this.state.email && (
                                <div className="b-section">
                                    <label>Email</label>
                                    <p>{this.state.email}</p>
                                </div>
                            )
                        }
                        {
                            this.state.facebook && (
                                <div className="b-section">
                                    <label>Facebook</label>
                                    <p>{this.state.facebook}</p>
                                </div>
                            )
                        }
                        {
                            this.state.instagram && (
                                <div className="b-section">
                                    <label>Instagram</label>
                                    <p>{this.state.instagram}</p>
                                </div>
                            )
                        }
                        <Button type="primary" onClick={this.addOrganization}>
                            Əlavə et
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.data.user_ID
    };
};
export default connect(mapStateToProps)(Organization);
