import React from "react";
import "./style/style.css";
import { Button } from "antd";
import { Link } from "react-router-dom";
import sualtap from "../../const/api";

const Phase = (props) => {
  let renderedButtons = null;
  if (props.phase.count) {
    renderedButtons = (
      <>
        <Button type="primary">
          <Link to={`/Phase/${props.phase.id}/new`}>
            {props.with_theme ? "Mövzu artır" : "Sual əlavə et"}
          </Link>
        </Button>
        <Button type="primary">
          <Link to={`/Phase/${props.phase.id}/See`}>
            {props.with_theme ? "Mövzulara" : "Suallara"} bax
          </Link>
        </Button>
      </>
    );
  } else if (props.phase.subs) {
    renderedButtons = (
      <Button onClick={() => props.showPhase(props.value)} type="primary">
        Etaplara bax
      </Button>
    );
  } else {
    renderedButtons = (
      <>
        <Button type="primary">
          <Link to={`/Phase/${props.phase.id}/new`}>
            + {props.with_theme ? "mövzu" : "sual"}
          </Link>
        </Button>
        {!props.sub ? (
          <Button
            onClick={() => props.openModal(true, props.phase.id)}
            type="primary"
          >
            + etap
          </Button>
        ) : null}
        <Button onClick={() => deletePhase()} type="primary">
          Sil
        </Button>
      </>
    );
  }

  const deletePhase = async () => {
    await sualtap
      .post(`phases/delete?id=${props.phase.id}`)
      .then((res) => console.log(res));
    props.rewind();
  };

  return (
    <div className="phase">
      <h3 className="bold">{props.phase.name}</h3>
      {props.phase.count !== null ? (
        <h4>{props.phase.count} sual / mövzu</h4>
      ) : (
        <h4>{props.phase.subs.length} oyun</h4>
      )}
      <div className="actions">{renderedButtons}</div>
    </div>
  );
};

export default Phase;
