import React from "react";
import sualtap from "./../../../const/api";
import { Table, Button, Input } from "antd";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { loadingOn, loadingOff } from './../../../redux/actions';
const { Search } = Input;

const columns = [
    {
        title: "ID",
        dataIndex: "id",
        key: "id"
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name"
    },
    {
        title: "Məlumat",
        dataIndex: "information",
        key: "information"
    },
    {
        title: "Növ",
        dataIndex: "isLeague",
        key: "isLeague"
    },
    {
        title: "Davamiyyət",
        dataIndex: "cont",
        key: "cont"
    },
    {
        title: "Əlavə et",
        dataIndex: "add",
        key: "add"
    },
    {
        title: "Bax",
        dataIndex: "see",
        key: "see"
    }
];
class Tournaments extends React.Component {
    state = {
        tournaments: []
    };

    fetchTournaments = async (val = "") => {
        this.props.loadingOn();
        let tournaments = [];
        await sualtap.get(`tournaments?word=${val}`).then(res => {
            res.data.map(({ name, id, isLeague, information, continuation }) =>
                tournaments.push({
                    name,
                    key: id,
                    id, information: information !==null ? information.substring(0, 50) : null,
                    cont: continuation ? continuation.keyword : null,
                    isLeague: isLeague ? <p>Liqa</p> : <p>Turnir</p>,
                    add: <Link to={`/tournament/${id}/new/package`}>Yeni paket</Link>,
                    see: this.props.user >= 4 ? <Link to={`/tournament/${id}/packages`}>Paketlərə bax</Link> : null
                })
            );
            let tournamentsreversed = tournaments.reverse()
            const training = tournamentsreversed.filter(t => t.cont === 'Məşq')[0];
            if (training) {
                const indexone = tournamentsreversed.indexOf(training);
                if (indexone !== 0) {
                    tournamentsreversed[indexone] = tournamentsreversed[0];
                    tournamentsreversed[0] = training;
                }
            }
            this.setState({
                tournaments: tournamentsreversed
            });
        });
        setTimeout(() => {
            this.props.loadingOff();
        }, 500)
    }

    componentDidMount = () => {
        this.fetchTournaments();
    };

    searchTournaments = (val) => {
        this.fetchTournaments(val);
    }

    render() {
        return (
            <>
                <div className="b-page-top">
                    <h2>Turnirlər</h2>
                    {
                        this.props.user > 3 ? (
                            <Button type="primary">
                                <Link to="/tournaments/new">Turnir əlavə et</Link>
                            </Button>
                        ) : null
                    }
                </div>
                <Search
                    className="search-tournament"
                    placeholder="Turnir axtar"
                    onSearch={value => this.searchTournaments(value)}
                />
                <div className="floater">
                    <Table dataSource={this.state.tournaments} columns={columns} />
                </div>
            </>
        );
    }
}
const mapStateToProps = ({ user }) => {
    return {
        user: user.data.role
    }
}

export default connect(mapStateToProps, { loadingOn, loadingOff })(Tournaments);