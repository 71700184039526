import React from "react";
import Type1 from "./Types/Type1";
import Type2 from "./Types/Type2";
import Type3 from "./Types/Type3";
import Type4 from "./Types/Type4";
import Type5 from "./Types/Type5";
import Type6 from "./Types/Type6";
import Type7 from "./Types/Type7";
import sualtap from "../../../const/api";
import { connect } from "react-redux";
import { loadingOn, loadingOff } from "./../../../redux/actions";
import { Tooltip, Input, message } from "antd";
import history from "./../../../const/history";

class Add extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    type: 1,
    data: {},
    isNotOriginal: false,
    phase: "",
    number: 1,
  };

  fetchNumber = async () => {
    this.props.loadingOn();
    await sualtap
      .get(`packages/client?phase=${this.props.params.id}`)
      .then((res) => {
        if (res.data.package.user_ID !== this.props.user.user_ID) {
          history.push("/my");
        }
        this.setState({
          type: res.data.package.game_type.id,
          data: res.data,
          isNotOriginal: res.data.package.isNotOriginal,
          phase: res.data.phases.filter(
            (ph) => ph.id === parseInt(this.props.params.id)
          )[0].name,
          number: res.data.package.game_type.with_Theme
            ? res.data.phases.filter(
                (ph) => ph.id === parseInt(this.props.params.id)
              )[0].themes.length + 1
            : res.data.phases.filter(
                (ph) => ph.id === parseInt(this.props.params.id)
              )[0].questions.length + 1,
        });
      });
    this.props.loadingOff();
  };

  componentDidMount = async () => {
    this.fetchNumber();
  };

  renderType() {
    switch (this.state.type) {
      case 1:
        return (
          <Type1
            number={this.state.number}
            fetch={this.fetchNumber}
            isNotOriginal={this.state.isNotOriginal}
            params={this.props.params}
          />
        );
      case 2:
        return (
          <Type2
            number={this.state.number}
            fetch={this.fetchNumber}
            isNotOriginal={this.state.isNotOriginal}
            params={this.props.params}
          />
        );
      case 3:
        return (
          <Type3
            number={this.state.number}
            fetch={this.fetchNumber}
            isNotOriginal={this.state.isNotOriginal}
            params={this.props.params}
          />
        );
      case 4:
        return (
          <Type4
            number={this.state.number}
            fetch={this.fetchNumber}
            isNotOriginal={this.state.isNotOriginal}
            params={this.props.params}
          />
        );
      case 5:
        return (
          <Type5
            number={this.state.number}
            fetch={this.fetchNumber}
            isNotOriginal={this.state.isNotOriginal}
            params={this.props.params}
          />
        );
      case 6:
        return (
          <Type6
            number={this.state.number}
            fetch={this.fetchNumber}
            isNotOriginal={this.state.isNotOriginal}
            params={this.props.params}
          />
        );
      case 7:
        return (
          <Type7
            number={this.state.number}
            fetch={this.fetchNumber}
            isNotOriginal={this.state.isNotOriginal}
            params={this.props.params}
          />
        );
      default:
        return null;
    }
  }

  copyToClipboard = () => {
    if (this.myRef.current !== null) {
      this.myRef.current.select();
      document.execCommand("copy");
      message.success("Uğurla kopyalandı!");
    }
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>
            {this.state.data.tournament &&
            this.state.data.tournament.isLeague &&
            this.state.data.tournament.continuation
              ? `${this.state.data.tournament.continuation} / `
              : null}
            {this.state.data.tournament
              ? this.state.data.tournament.name
              : null}{" "}
            /{" "}
            {this.state.data.package && this.state.data.package.name
              ? `${this.state.data.package.name} / `
              : ``}
            {this.state.phase !== "" ? `${this.state.phase}` : null}
          </h2>
          <Tooltip
            placement="topLeft"
            title="Aşağı sətrə düşmək üçün kopyalayıb mətnə əlavə edin"
          >
            <Input
              ref={this.myRef}
              onClick={() => this.copyToClipboard()}
              value="/-/"
              className="clipboard"
              readOnly
            />
          </Tooltip>
        </div>
        {this.renderType()}
      </>
    );
  }
}
const mapStateToProps = ({ user }) => {
  return {
    user: user.data,
  };
};

export default connect(mapStateToProps, { loadingOn, loadingOff })(Add);
