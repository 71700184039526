import React from "react";
import "../../../assets/css/menu.css";
// import { Avatar } from "antd";
// import ProfilePic from "./../../../assets/img/drogbaa.jpg";
import { connect } from "react-redux";
import history from "./../../../const/history";
import { Icon } from "antd";

const TopMenu = props => {
  const goBack = () => {
    history.goBack();
  };
  const logOut = () => {
    localStorage.removeItem("sualtapUser");
    window.location.reload();
  };
  return (
    <div className="topMenu">
      <div className="flex">
        <p className="goBack" onClick={goBack}>
          Geri
        </p>
        <h3>{props.user.name + " " + props.user.surname}</h3>
      </div>
      <p className="goBack margin0" onClick={logOut}>
        <Icon type="logout" />
      </p>
    </div>
  );
};
const mapStateToProps = ({ user }) => {
  return {
    user: user.data
  };
};

export default connect(mapStateToProps)(TopMenu);
