import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';
import { logInUser, loadingOff, loadingOn } from './../../../redux/actions/index';
import './style/login.css';

const Login = props => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        if (props.message.trim().length !== 0) {
            message.warning(props.message);
        }
    }, [props.message, props.notify])

    const logUserIn = async () => {
        props.loadingOn();
        await props.logInUser(email, password)
        setTimeout(function () {
            props.loadingOff();
        }, 1000)
    }

    return (
        <div className="admin-login">
            <div className="admin-login-row">
                <h5>3sual.az Admin</h5>
            </div>
            <div className="admin-login-row">
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Elektron poçt" />
            </div>
            <div className="admin-login-row">
                <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Şifrə" />
            </div>
            <div className="admin-login-row">
                <input onClick={logUserIn} type="button" value="Daxil ol" />
            </div>
        </div>
    )
}
const mapStateToProps = ({ user }) => {
    return {
        loggedIn: user.isLoggedIn,
        message: user.message,
        notify: user.notify
    }
}

export default connect(mapStateToProps, { logInUser, loadingOn, loadingOff })(Login);