import React from "react";
import { Table, Select, message } from "antd";
import sualtap from "./../../const/api";
import { connect } from "react-redux";
import { loadingOn, loadingOff } from "./../../redux/actions";
const { Option } = Select;

const columns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Tam ad",
    dataIndex: "fullname",
    key: "fullname"
  },
  {
    title: "İstifadəçi adı",
    dataIndex: "username",
    key: "username",
    render: (text) => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://3sual.az/profile/${text}`}
      >
        {text}
      </a>
    ),
  },
  {
    title: "Rol",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Telefon",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Qeydiyyat",
    dataIndex: "registered",
    key: "registered",
    render: (val) => <p>{val.substring(0, 10)}</p>,
  },
  {
    title: "Təsdiq",
    dataIndex: "confirmed",
    key: "confirmed",
    render: (val) => <p>{val ? "Bəli" : "Xeyr"}</p>,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
];

class Users extends React.Component {
  state = {
    users: [],
    total: 10,
  };

  fetchUsers = async (p) => {
    this.props.loadingOn();
    await sualtap.get(`users?page=${p}`).then(
      (res) => {
        let ob = [];
        res.data.users.forEach((element) => {
          element.fullname = element.name + " " + element.surname;
          ob.push(element);
        });
        this.setState({
          users: ob,
          total: res.data.total
        });
      }
    );
    this.props.loadingOff();
  };

  componentDidMount() {
    this.fetchUsers(1);
  }

  handleRoleChange = async (e, id) => {
    await sualtap
      .put(`users/role`, {
        userID: id,
        roleID: e,
        // ...this.state.users.filter((u) => u.id === id)[0],
        // user_role_ID: e,
      })
      .then((res) => message.success("Dəyişiklik edildi"));
  };

  changePage = (e) => {
    this.fetchUsers(e.current);
  };

  render() {
    const dataSource = this.state.users.map((user) => ({
      key: user.id,
      ...user,
      role:
        this.props.user === 5 && user.user_role_ID !== 1 ? (
          <Select
            defaultValue={user.user_role_ID.toString()}
            style={{ width: 130 }}
            onChange={(e) => this.handleRoleChange(e, user.id)}
          >
            <Option value="2">Admin</Option>
            <Option value="3">Moderator</Option>
            <Option value="4">Təşkilatçı</Option>
            <Option value="5">İstifadəçi</Option>
          </Select>
        ) : null,
    }));

    return (
      <React.Fragment>
        <div className="b-page-top">
          <h2>İstifadəçilər</h2>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{ pageSize: 10, total: this.state.total }}
          onChange={this.changePage}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user: user.data.role,
  };
};

export default connect(mapStateToProps, { loadingOff, loadingOn })(Users);
