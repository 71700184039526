import React from "react";
import "./style/Add.css";
import {
  Select,
  DatePicker,
  Button,
  Spin,
  Switch,
  Icon,
  Modal,
  Input,
  message,
} from "antd";
import { connect } from "react-redux";
import sualtap from "./../../../const/api";
import history from "./../../../const/history";
import { loadingOn, loadingOff } from "./../../../redux/actions";
const { Option } = Select;

class Package extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allAuthors: [],
      tournamentName: "",
      newEditor: "",
      modalVisible: false,
      selectedAuthors: [],
      authorsFetching: false,
      showName: true,
      showInfo: true,
      selectedTags: [],
      tagsFetching: false,
      games: [],
      newAuthor: "",
      selectedGame: 1,
      name: "",
      playedOn: "",
      information: "",
      tags: [],
      authors: [],
      isTranslated: false,
      continuation: "",
      isLeague: false,
    };
    this.lastTagFetchId = 0;
    this.lastAuthorFetchId = 0;
  }

  validatePackage() {
    let ret = true;
    if (this.state.name.length < 3 && this.state.showName) {
      message.warning("Ad mütləq daxil edilməlidir");
      ret = false;
    }
    if (this.state.information.length < 3 && this.state.showInfo) {
      message.warning("Məlumat mütləq daxil edilməlidir");
      ret = false;
    }
    if (this.state.playedOn.length < 1) {
      message.warning("Oynanıldığı tarix seçilməlidir");
      ret = false;
    }
    if (this.state.selectedTags.length === 0) {
      message.warning("Ən az 1 teq mütləq seçilməlidir");
      ret = false;
    }
    return ret;
  }
  showObject = async () => {
    if (this.validatePackage()) {
      this.props.loadingOn();
      let user = null;
      if (localStorage.getItem("sualtapUser")) {
        user = JSON.parse(localStorage.getItem("sualtapUser")).user_ID;
      }
      const obj = {
        authors: this.state.selectedAuthors.map((a) => parseInt(a.key)),
        tags: this.state.selectedTags.map((tag) => parseInt(tag.key)),
        game: this.state.selectedGame,
        name: this.state.showName ? this.state.name.trim() : null,
        playedon: this.state.playedOn,
        information: this.state.showInfo ? this.state.information.trim() : null,
        tournament: parseInt(this.props.params.id),
        user_ID: user,
        isTranslated: this.state.isTranslated,
      };
      try {
        await sualtap.post("packages", JSON.stringify(obj)).then((res) => {
          history.push(`/Tournament/${this.props.params.id}/Packages`);
        });
      } catch (e) {
        history.push("/");
      }
      this.props.loadingOff();
    }
  };
  fetchGames = async () => {
    await sualtap
      .get("game_type")
      .then((res) => this.setState({ games: [...res.data] }));
    this.props.loadingOff();
  };
  componentDidMount = () => {
    sualtap.get(`/tournaments/${this.props.params.id}`).then((res) =>
      this.setState({
        tournamentName: res.data.name,
        isLeague: res.data.isLeague,
        continuation:
          res.data.isLeague === true ? res.data.continuation.keyword : null,
      })
    );
    this.props.loadingOn();
    this.fetchAuthors();
    this.fetchGames();
  };
  fetchAuthors = async () => {
    await sualtap.get("authors").then((res) => {
      console.log(res.data);
      this.setState({
        allAuthors: res.data,
      });
    });
  };
  handleNewAuthorChange(e) {
    this.setState({ newAuthor: e.target.value });
  }
  handleAuthorSubmit = async (e) => {
    e.preventDefault();
    await sualtap
      .post(`add/author`, JSON.stringify({ fullname: this.state.newAuthor }))
      .then(this.setState({ newAuthor: "" }));
  };
  handleGameChange(e) {
    this.setState({
      selectedGame: e,
    });
  }
  // Tags search
  filterTagsSearch = async (value) => {
    this.lastTagFetchId += 1;
    const tagFetchId = this.lastTagFetchId;
    this.setState({ tags: [], tagsFetching: true });
    await sualtap.get(`tags?search=${value}`).then((res) => {
      if (tagFetchId !== this.lastTagFetchId) {
        return;
      }
      this.setState({ tags: res.data, tagsFetching: false });
    });
  };
  handleTagsSearchChange = (value) => {
    this.setState({
      selectedTags: value,
      tags: [],
      tagsFetching: false,
    });
  };
  // Authors search
  filterAuthorsSearch = async (value) => {
    this.lastAuthorFetchId += 1;
    const authorFetchId = this.lastAuthorFetchId;
    this.setState({ authors: [], authorsFetching: true });
    let authores = this.state.allAuthors.filter((a) =>
      a.fullname.toLowerCase().includes(value.toLowerCase())
    );
    if (authorFetchId !== this.lastAuthorFetchId) {
      return;
    }
    this.setState({ authors: authores, authorsFetching: false });
  };
  handleAuthorsSearchChange = (value) => {
    this.setState({
      selectedAuthors: value,
      authors: [],
      authorsFetching: false,
    });
  };
  // Hide changes
  handleshowNameChange = () =>
    this.setState({ showName: !this.state.showName });
  handleshowInfoChange = () =>
    this.setState({ showInfo: !this.state.showInfo });

  //handleInputChanges
  handleNameChange = (e) => this.setState({ name: e.target.value });
  handleInfoChange = (e) => this.setState({ information: e.target.value });
  handleIsTranslatedChange = (e) =>
    this.setState({ isTranslated: !this.state.isTranslated });
  handleDateChange = (e) => {
    this.setState({
      playedOn:
        e._d.getFullYear() +
        "-" +
        (e._d.getMonth() + 1).toString().padStart(2, 0) +
        "-" +
        e._d.getDate().toString().padStart(2, 0),
    });
  };

  setModalVisible = (val) => {
    this.setState({ modalVisible: val });
  };
  handleNewEditor = (e) => {
    this.setState({ newEditor: e.target.value });
  };
  addEditor = async (e) => {
    await sualtap
      .post(`authors`, JSON.stringify({ fullname: this.state.newEditor }))
      .then(
        this.setState(
          { newEditor: "", modalVisible: false },
          message.warning("Yeni redaktor əlavə olundu")
        )
      );
  };

  render() {
    const gamesChildren = [];
    this.state.games.forEach((game) => {
      gamesChildren.push(
        <Option key={game.id} value={game.id}>
          {game.name}
        </Option>
      );
    });
    const { tagsFetching, tags, selectedTags } = this.state;
    const { authorsFetching, authors, selectedAuthors } = this.state;
    const gameName = {
      ...this.state.games.find((g) => g.id === this.state.selectedGame),
    };
    const tagsList = this.state.selectedTags.map((t) => t.label);
    const authList = this.state.selectedAuthors.map((a) => a.label);

    return (
      <>
        <div className="b-page-top">
          <h2>
            {this.state.isLeague ? `${this.state.continuation} / ` : null}
            {this.state.tournamentName}
          </h2>
        </div>
        <div className="b-add-box">
          <div className="b-add-box-left">
            <div className="b-form-group two-items">
              <Switch
                checkedChildren="Ad lazımdır"
                onChange={this.handleshowNameChange}
                checked={this.state.showName}
                unCheckedChildren="Ad lazım deyil"
                defaultChecked
              />
              <Switch
                checkedChildren="Məlumat lazımdır"
                onChange={this.handleshowInfoChange}
                checked={this.state.showInfo}
                unCheckedChildren="Məlumat lazım deyil"
                defaultChecked
              />
              <Switch
                checkedChildren="Paket yığılmadır"
                onChange={this.handleIsTranslatedChange}
                checked={this.state.isTranslated}
                unCheckedChildren="Paket originaldır"
                defaultChecked
              />
            </div>
            {this.state.showName ? (
              <div className="b-form-group">
                <input
                  placeholder="Paketin adı"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  type="text"
                />
              </div>
            ) : null}
            {this.state.showInfo ? (
              <div className="b-form-group">
                <textarea
                  value={this.state.information}
                  onChange={this.handleInfoChange}
                  type="text"
                  placeholder="Paket haqqında ətraflı məlumat"
                ></textarea>
              </div>
            ) : null}
            <div className="b-form-group two-items">
              <div className="multi-item">
                <label style={{ margin: "10px 0 -2px 0" }}>
                  Oynanıldığı tarix
                </label>
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={this.handleDateChange}
                  className="package-played-on multi-item-content"
                />
              </div>
              <div className="multi-item">
                <label>Oyun növü</label>
                <Select
                  size="large"
                  defaultValue="1"
                  className="multi-item-content"
                  value={this.state.selectedGame}
                  onChange={(e) => this.handleGameChange(e)}
                >
                  {gamesChildren}
                </Select>
              </div>
            </div>
            <div className="b-form-group">
              <label>Teqlər</label>
              <Select
                mode="multiple"
                labelInValue
                value={selectedTags}
                placeholder="Teq axtar"
                notFoundContent={tagsFetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.filterTagsSearch}
                onChange={this.handleTagsSearchChange}
                style={{ width: "100%" }}
              >
                {tags.map((d) => (
                  <Option key={d.id}>{d.name}</Option>
                ))}
              </Select>
            </div>
            <div className="b-form-group">
              <label>Redaktorları seç</label>
              <Select
                mode="multiple"
                labelInValue
                value={selectedAuthors}
                placeholder="Redaktor axtar"
                notFoundContent={authorsFetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={this.filterAuthorsSearch}
                onChange={this.handleAuthorsSearchChange}
                style={{ width: "100%" }}
              >
                {authors.map((a) => (
                  <Option key={a.id}>{a.fullname}</Option>
                ))}
              </Select>
            </div>
            <Button
              className="btn btn-continue"
              type="primary"
              block
              onClick={this.showObject}
            >
              Əlavə et
            </Button>
          </div>
          <div className="b-add-box-right">
            {this.state.showName ? (
              <div className="b-section">
                <label>Paketin adı</label>
                <p>{this.state.name}</p>
              </div>
            ) : null}
            {this.state.showInfo ? (
              <div className="b-section">
                <label>Ətraflı məlumat</label>
                <p>{this.state.information}</p>
              </div>
            ) : null}
            <div className="b-section">
              <label>Oynanıldığı tarix</label>
              <p>{this.state.playedOn}</p>
            </div>
            <div className="b-section">
              <label>Oyun növü</label>
              <p>{gameName.name}</p>
            </div>
            <div className="b-section">
              <label>Teqlər</label>
              <p>{tagsList.join(", ")}</p>
            </div>
            <div className="b-section">
              <label>Redaktorlar</label>
              <p>{authList.join(", ")}</p>
            </div>
          </div>
        </div>
        {this.props.user > 3 ? (
          <div onClick={() => this.setModalVisible(true)} className="cont-box">
            <Icon type="user-add" />
          </div>
        ) : null}
        <Modal
          title="Redaktor əlavə et"
          cancelText="Ləğv et"
          okText="Əlavə et"
          centered
          visible={this.state.modalVisible}
          onOk={this.addEditor}
          onCancel={() => this.setModalVisible(false)}
        >
          <label>Açar söz</label>
          <Input value={this.state.newEditor} onChange={this.handleNewEditor} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user: user.data.role,
  };
};

export default connect(mapStateToProps, { loadingOn, loadingOff })(Package);
