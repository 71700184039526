import React from "react";
import siteUrl from "./../../../../const/const";
import {
  Upload,
  Icon,
  Radio,
  Button,
  Select,
  Spin,
  Switch,
  message,
} from "antd";
import "./style/types.css";
import Loader from "../../Loader";
import sualtap from "./../../../../const/api";
import { connect } from "react-redux";
import { loadingOn, loadingOff } from "./../../../../redux/actions";
const { Option } = Select;

const props2 = {
  listType: "picture",
  className: "upload-list-inline",
  accept: ".png,.jpg,.jpeg",
};

class Type7 extends React.Component {
  constructor(props) {
    super(props);
    this.lastAuthorFetchId = 0;
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      allAuthors: [],
      selectedAuthors: [],
      authorsFetching: false,
      theme: "",
      loading: false,
      hasNoteBefore: false,
      isTranslated: false,
      activePart: 1,
      questions: [
        {
          question: "",
          answer: "",
          comment: "",
          sources: [],
          source: "",
          has_media: false,
          rekvizit: null,
          source_media: null,
        },
        {
          question: "",
          answer: "",
          comment: "",
          sources: [],
          source: "",
          has_media: false,
          rekvizit: null,
          source_media: null,
        },
        {
          question: "",
          answer: "",
          comment: "",
          sources: [],
          source: "",
          has_media: false,
          rekvizit: null,
          source_media: null,
        },
        {
          question: "",
          answer: "",
          comment: "",
          sources: [],
          source: "",
          has_media: false,
          rekvizit: null,
          source_media: null,
        },
        {
          question: "",
          answer: "",
          comment: "",
          sources: [],
          source: "",
          has_media: false,
          rekvizit: null,
          source_media: null,
        },
      ],
      noteBefore: "",
      source: "",
      authors: [],
    };
  }

  componentDidMount() {
    this.fetchAuthors();
  }

  addAQuestion = () => {
    const st = { ...this.state };
    st.questions = [
      ...st.questions,
      {
        question: "",
        answer: "",
        comment: "",
        sources: [],
        source: "",
        has_media: false,
        rekvizit: null,
        source_media: null,
      },
    ];
    this.setState({ ...st });
  };

  removeAQuestion = () => {
    const st = { ...this.state };
    let obj = [];
    this.state.questions.forEach((el, index) => {
      if (index !== this.state.questions.length - 1) {
        obj.push({ ...el });
      }
    });
    st.questions = obj;
    if (st.activePart === this.state.questions.length) {
      st.activePart = st.activePart - 1;
    }
    this.setState({ ...st });
  };

  fetchAuthors = async () => {
    await sualtap.get("authors").then((res) => {
      this.setState({
        allAuthors: res.data,
      });
    });
  };

  handleTypeChange = (e) => {
    console.log(e.target.value);
    this.setState({ activePart: parseInt(e.target.value) });
  };
  handleIsTranslatedChange() {
    this.setState({ isTranslated: !this.state.isTranslated });
  }
  renderNoteBefore() {
    return this.state.hasNoteBefore ? (
      <textarea
        onChange={(e) => this.handleNoteBeforeTextChange(e)}
        value={this.state.noteBefore}
        placeholder="Mövzu üçün qeyd"
      ></textarea>
    ) : null;
  }
  handleNoteBeforeChange() {
    this.setState({
      hasNoteBefore: !this.state.hasNoteBefore,
    });
  }

  handleSourceChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].source = e.target.value;
    obj[i].sources =
      e.target.value.trim() !== ""
        ? e.target.value
            .trim()
            .split("/-/")
            .map((a) => a.trim())
        : [];
    this.setState({
      questions: obj,
    });
  }
  // Authors search
  filterAuthorsSearch = async (value) => {
    this.lastAuthorFetchId += 1;
    const authorFetchId = this.lastAuthorFetchId;
    this.setState({ authors: [], authorsFetching: true });
    let authores = this.state.allAuthors.filter((a) =>
      a.fullname.toLowerCase().includes(value.toLowerCase())
    );
    if (authorFetchId !== this.lastAuthorFetchId) {
      return;
    }
    this.setState({ authors: authores, authorsFetching: false });
  };
  handleAuthorsSearchChange = (value) => {
    this.setState({
      selectedAuthors: value,
      authors: [],
      authorsFetching: false,
    });
  };

  renderQuestions() {
    let resp = [];
    for (let i = 0; i < this.state.questions.length; i++) {
      if (i + 1 === this.state.activePart) {
        resp.push(
          <div key={i} className="single-question">
            <div className="question-number">{i + 1}</div>
            <textarea
              className="green-border"
              placeholder="Sualın mətnini daxil edin"
              onChange={(e) => this.handleQuestionTextChange(e, i)}
              value={this.state.questions[i].question}
            ></textarea>
            <input
              className="green-border"
              type="text"
              value={this.state.questions[i].answer}
              onChange={(e) => this.handleAnswerChange(e, i)}
              placeholder="Sualın cavabı"
            />
            <input
              className="green-border"
              type="text"
              value={this.state.questions[i].comment}
              onChange={(e) => this.handleCommentChange(e, i)}
              placeholder="Sualın şərhi"
            />
            <input
              className="green-border"
              type="text"
              value={this.state.questions[i].source}
              onChange={(e) => this.handleSourceChange(e, i)}
              placeholder="Sualın mənbəsi"
            />
            <div className="w-100">
              <Switch
                className="mt-10"
                checkedChildren="Rekvizit var"
                onChange={(e) => this.handleHasMediaChange(e, i)}
                unCheckedChildren="Rekvizit yoxdur"
                checked={this.state.questions[i].has_media}
              />
            </div>
            {this.state.questions[i].has_media ? (
              <div className="w-100">
                <div className="rekvizit_Uploader">
                  <Upload
                    {...props2}
                    onChange={(e) => this.handleUploadChange(e, i, false)}
                    customRequest={(e) => this.uploadImage(e, i, false)}
                  >
                    <Button>
                      <Icon type="upload" /> Rekvizit
                    </Button>
                  </Upload>
                </div>
                <div className="rekvizit_Uploader">
                  <Upload
                    {...props2}
                    onChange={(e) => this.handleUploadChange(e, i, true)}
                    customRequest={(e) => this.uploadImage(e, i, true)}
                  >
                    <Button>
                      <Icon type="upload" /> Foto mənbə
                    </Button>
                  </Upload>
                </div>
              </div>
            ) : null}
          </div>
        );
      }
    }
    return resp.map((a) => a);
  }

  handleUploadChange(e, i, source) {
    let obj = [...this.state.questions];
    if (source) {
      obj[i].source_media = null;
    } else {
      obj[i].rekvizit = null;
    }
    console.log(obj);
    this.setState({
      questions: obj,
    });
  }

  uploadImage(e, i, source) {
    let form_data = new FormData();
    const filename = Math.random(1, 999999) + Date.now() + e.file.name;
    form_data.append("image", e.file, filename);
    sualtap
      .post("questions/rekvizit", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        const obj = [...this.state.questions];
        if (source) {
          obj[i].source_media = res.data;
        } else {
          obj[i].rekvizit = res.data;
        }
        this.setState({
          questions: obj,
        });
      })
      .catch((err) => console.log(err));
  }

  handleQuestionTextChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].question = e.target.value;
    console.log(obj);
    this.setState({
      questions: obj,
    });
  }
  handleHasMediaChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].has_media = !obj[i].has_media;
    this.setState({
      questions: obj,
    });
  }
  handleCommentChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].comment = e.target.value;
    this.setState({
      questions: obj,
    });
  }
  handleThemeChange = (e) => {
    this.setState({ theme: e.target.value });
  };
  handleAnswerChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].answer = e.target.value;
    this.setState({
      questions: obj,
    });
  }
  handleNoteBeforeTextChange(e) {
    this.setState({
      noteBefore: e.target.value,
    });
  }
  submitQuestion = () => {
    var obj = {
      theme: this.state.theme,
      information:
        this.state.noteBefore.trim().length !== 0
          ? this.state.noteBefore.trim()
          : null,
      values: this.state.questions.map((ques) => {
        return {
          ...ques,
          comment: ques.comment.trim().length ? ques.comment.trim() : null,
        };
      }),
      authors: this.state.selectedAuthors.map((k) => parseInt(k.key)),
      phase: parseInt(this.props.params.id),
      isNotOriginal: this.state.isTranslated,
    };
    if (this.validateQuestion()) {
      this.props.loadingOn();
      sualtap.post("questions/quiz", JSON.stringify(obj)).then((res) => {
        this.setState(this.getInitialState());
        this.props.loadingOff();
        this.fetchAuthors();
        this.props.fetch();
      });
    }
  };
  isNullOrEmpty(str) {
    return str === null || str.trim().length === 0 ? true : false;
  }
  validateQuestion = () => {
    let validated = true;
    if (this.state.theme.trim().length === 0) {
      message.warning("Mövzunun adını təyin edin");
      validated = false;
    }
    for (let i = 0; i < this.state.questions.length; i++) {
      if (this.state.questions[i].question.length === 0) {
        if (i === 2 || i === 3) {
          message.warning(`${i + 1}-cü sualın mətnini daxil edin`);
          validated = false;
        } else if (i === 5 || i === 8) {
          message.warning(`${i + 1}-cı sualın mətnini daxil edin`);
          validated = false;
        } else {
          message.warning(`${i + 1}-ci sualın mətnini daxil edin`);
          validated = false;
        }
      }
      if (this.state.questions[i].answer.length === 0) {
        if (i === 2 || i === 3) {
          message.warning(`${i + 1}-cü sualın cavabını daxil edin`);
          validated = false;
        } else if (i === 5 || i === 8) {
          message.warning(`${i + 1}-cı sualın cavabını daxil edin`);
          validated = false;
        } else {
          message.warning(`${i + 1}-ci sualın cavabını daxil edin`);
          validated = false;
        }
      }
    }
    return validated;
  };

  render() {
    return (
      <>
        {this.state.loading ? <Loader /> : null}
        <div className="b-add-box">
          <div className="b-add-box-left bg-white">
            <div className="b-type-radios-5">
              <Radio.Group
                defaultValue={this.state.activePart}
                value={this.state.activePart}
                buttonStyle="solid"
                onChange={this.handleTypeChange}
              >
                {this.state.questions.map((q, index) => {
                  return (
                    <Radio.Button key={index} value={index + 1}>
                      Sual {index + 1}
                    </Radio.Button>
                  );
                })}
                {this.state.questions.length < 9 ? (
                  <Button onClick={this.addAQuestion}>Əlavə sual</Button>
                ) : null}
                {this.state.questions.length > 5 ? (
                  <Button onClick={this.removeAQuestion}>Sualı sil</Button>
                ) : null}
              </Radio.Group>
            </div>
            {!this.props.isNotOriginal ? (
              <Switch
                className="mt-10"
                checkedChildren="Mövzu tərcümədir"
                onChange={() => this.handleIsTranslatedChange()}
                unCheckedChildren="Mövzu originaldır"
                checked={this.state.isTranslated}
              />
            ) : null}
            <Switch
              className="mt-10"
              checkedChildren="Mövzu üçün qeyd var"
              onChange={() => this.handleNoteBeforeChange()}
              unCheckedChildren="Mövzu üçün qeyd yoxdur"
              checked={this.state.hasNoteBefore}
            />
            {this.renderNoteBefore()}
            <div className="question-details">
              <input
                type="text"
                value={this.state.theme}
                onChange={(e) => this.handleThemeChange(e)}
                placeholder="Mövzunun adı"
              />
            </div>
            {this.renderQuestions()}
            <Select
              mode="multiple"
              labelInValue
              value={this.state.selectedAuthors}
              placeholder="Müəllifləri seç"
              notFoundContent={
                this.state.authorsFetching ? <Spin size="small" /> : null
              }
              filterOption={false}
              onSearch={this.filterAuthorsSearch}
              onChange={this.handleAuthorsSearchChange}
              style={{ width: "100%" }}
            >
              {this.state.authors.map((a) => (
                <Option key={a.id}>{a.fullname}</Option>
              ))}
            </Select>
          </div>
          <div className="b-add-box-right bg-white">
            <h3>
              <b>Mövzu nömrə: {this.props.number}</b>
            </h3>
            {this.state.theme ? (
              <h3>
                <b>Mövzu:</b> {this.state.theme}
              </h3>
            ) : (
              ""
            )}
            {this.state.noteBefore.trim() ? (
              <div className="b-question-item b-answer">
                <span>Mövzu üçün qeyd: </span>
                {this.state.noteBefore}
              </div>
            ) : null}
            {this.state.questions.map((q) => {
              const key = this.state.questions.indexOf(q) + 1;
              const main = q.question.split("/-/");
              let towrite = main.map((m, index) => {
                return (
                  <span key={index}>
                    {index ? <span>&nbsp;&nbsp;</span> : null}
                    {m}
                    <br />
                  </span>
                );
              });
              return (
                <div key={key} className="b-question-content">
                  <p>
                    {key}.&nbsp;
                    {towrite}
                  </p>
                  {q.rekvizit !== null ? (
                    <img
                      alt={q.rekvizit}
                      src={`${siteUrl}images/temp/${q.rekvizit}`}
                    />
                  ) : null}
                  <div className="b-question-item b-answer">
                    <span>Cavab: </span>
                    {q.answer}
                  </div>
                  {q.comment ? (
                    <div className="b-question-item b-answer">
                      <span>Şərh: </span>
                      {q.comment}
                    </div>
                  ) : null}
                  {q.sources.length > 0 ? (
                    <div className="b-question-item b-answer">
                      <p>
                        <span>Mənbə:&nbsp;</span>
                        {/* {this.state.source} */}
                        {q.sources.map((q, index) => {
                          return (
                            <React.Fragment key={index}>
                              {index > 0 ? (
                                <>
                                  <br />{" "}
                                  <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                </>
                              ) : null}
                              {index + 1 + ". " + q}
                            </React.Fragment>
                          );
                        })}
                      </p>
                    </div>
                  ) : null}
                  {q.source_media !== null ? (
                    <div className="b-question-item b-answer">
                      <p>Foto mənbə</p>
                      <img
                        alt={q.source_media}
                        src={`${siteUrl}images/temp/${q.source_media}`}
                      />
                    </div>
                  ) : null}
                </div>
              );
            })}
            {this.state.selectedAuthors.length > 0 ? (
              <div className="b-question-item b-answer">
                <span>
                  {this.state.selectedAuthors.length > 1
                    ? "Müəlliflər: "
                    : "Müəllif: "}
                </span>
                {this.state.selectedAuthors.map((aut, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && index < this.state.selectedAuthors.length
                      ? ", "
                      : ""}
                    {aut.label}
                  </React.Fragment>
                ))}
              </div>
            ) : null}
            <div className="b-question-item mt-10">
              <Button onClick={this.submitQuestion} type="primary">
                Əlavə et
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { loadingOn, loadingOff })(Type7);
