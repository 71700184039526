export const SET_USER = 'SET_USER';
export const SET_USER_ERROR = 'SET_USER_ERROR';
export const SET_USER_LOGGED_IN = 'SET_USER_LOGGED_IN';
export const GET_USER = 'GET_USER';
export const LOG_OUT = 'LOG_OUT';


export const SET_AUTHORS = 'SET_AUTHORS';

export const LOADING_ON = "LOADING_ON";
export const LOADING_OFF = "LOADING_OFF";
