import {
  SET_USER,
  SET_USER_ERROR,
  SET_USER_LOGGED_IN,
  LOADING_ON,
  LOADING_OFF,
} from "./../types";
import sualtap from "./../../const/api";

export const logUserIn = (data) => async (dispatch) => {
  let newData = null;
  await sualtap
    .get(`users/current?token=${data.token}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${data.token}`,
      },
    })
    .then((res) => {
      newData = res.data;
    });
  localStorage.setItem(
    "sualtapUser",
    JSON.stringify({ ...newData, lastLogin: new Date() })
  );
  dispatch({
    type: SET_USER_LOGGED_IN,
    payload: newData,
  });
  dispatch({
    type: LOADING_OFF,
  });
};
export const logInUser = (email, password) => async (dispatch) => {
  if (email.trim().length === 0 || password.trim().length === 0) {
    dispatch({
      type: SET_USER_ERROR,
      payload: { message: "Email və şifrə daxil edilməlidir" },
    });
  } else {
    var obj = {
      email,
      password,
    };
    await sualtap
      .post(`auth/login`, JSON.stringify(obj))
      .then((res) => {
        if (res.data.success) {
          console.log(res.data);
          if (res.data.role < 3) {
            dispatch({
              type: SET_USER_ERROR,
              payload: { message: "Sizin admin rolunuz yoxdur." },
            });
          } else {
            localStorage.setItem(
              "sualtapUser",
              JSON.stringify({ ...res.data, lastLogin: new Date() })
            );
            dispatch({
              type: SET_USER,
              payload: { data: res.data },
            });
          }
        } else {
          dispatch({
            type: SET_USER_ERROR,
            payload: { message: res.data.message },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_USER_ERROR,
          payload: { message: error.message },
        });
      });
  }
};

export const loadingOn = () => ({ type: LOADING_ON });
export const loadingOff = () => ({ type: LOADING_OFF });
