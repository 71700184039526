import React from "react";
import {
  Button,
  Row,
  Col,
  Select,
  Switch,
  Input,
  Modal,
  Icon,
  Spin,
  message
} from "antd";
import "./style/Tournament.css";
import sualtap from "./../../../const/api";
import { Link } from "react-router-dom";
// import Loader from "./../../../assets/img/loader.gif";
import Loader from "./../../Elements/Loader";
import history from "./../../../const/history";
import debounce from "lodash/debounce";
import { connect } from "react-redux";
const { TextArea } = Input;
const { Option } = Select;

class Tournament extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTraining: false,
      organizers: [],
      selectedOrganizers: [],
      continuations: [],
      continuation: null,
      newTournament: {
        name: "",
        isLeague: false,
        organizers: [],
        information: ""
      },
      newPackages: [],
      newQuestion: {},
      isLoading: false,
      modalVisible: false,
      newContinuation: "",
      usersData: [],
      userValue: [],
      userFetching: false,
      newOrganizer: "",
      needNewOrganizer: false,
      age_group: null
    };
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 800);
  }

  handleAgeChange = e => {
    this.setState({
      age_group: e.target.value
    });
  };
  handleNameChange = e => {
    this.setState({
      newTournament: {
        ...this.state.newTournament,
        name: e.target.value
      }
    });
  };
  handleInfoChange = e => {
    this.setState({
      newTournament: {
        ...this.state.newTournament,
        information: e.target.value
      }
    });
  };
  handleIsLeagueChange = () => {
    this.setState({
      newTournament: {
        ...this.state.newTournament,
        isLeague: !this.state.newTournament.isLeague
      }
    });
  };
  handleIsTrainingChange = () => {
    this.setState({
      isTraining: !this.state.isTraining
    });
  };
  handleNeedNewOrganizerChange = () => {
    this.setState({
      needNewOrganizer: !this.state.needNewOrganizer
    });
  };
  handleChange = selectedOrganizers => {
    this.setState({ selectedOrganizers });
  };
  validateNewTournament = () => {
    let nameErr, infoErr, orgaErr;
    this.state.newTournament.name.trim().length === 0
      ? (nameErr = true)
      : (nameErr = false);
    // this.state.newTournament.information.trim().length === 0
    //   ? (infoErr = true)
    //   : (infoErr = false);
    this.state.selectedOrganizers.length === 0 &&
    this.state.userValue.length === 0 &&
    this.state.newOrganizer.trim().length === 0
      ? (orgaErr = true)
      : (orgaErr = false);
    if (this.state.isTraining) {
      orgaErr = false;
    }
    if (!nameErr && !infoErr && !orgaErr) {
      return true;
    }
    let text = "";
    if (nameErr) {
      if (infoErr) {
        if (orgaErr) {
          text = "Ad, məlumat və təşkilatçı";
        } else {
          text = "Ad və məlumat";
        }
      } else {
        if (orgaErr) {
          text = "Ad və təşkilatçı";
        } else {
          text = "Ad";
        }
      }
    } else {
      if (infoErr) {
        if (orgaErr) {
          text = "Məlumat və təşkilatçı";
        } else {
          text = "Məlumat";
        }
      } else {
        if (orgaErr) {
          text = "Təşkilatçı";
        }
      }
    }
    message.warning(text + " mütləq daxil edilməlidir");
    return false;
  };
  handleSubmit = async () => {
    if (this.validateNewTournament()) {
      this.setState({ isLoading: true });
      const obj = {
        Tournament: {
          Name: this.state.newTournament.name.trim(),
          Information:
            this.state.newTournament.information.trim().length === 0
              ? null
              : this.state.newTournament.information.trim(),
          isLeague: this.state.newTournament.isLeague,
          Max_age:
            this.state.age_group !== null && this.state.age_group.length !== 0
              ? parseInt(this.state.age_group)
              : null
        },
        Organizations: this.state.selectedOrganizers,
        Users: this.state.userValue.map(u => parseInt(u.key)),
        Continuation: this.state.continuation,
        NewOrganizer: this.state.needNewOrganizer
          ? this.state.newOrganizer.trim()
          : null
      };
      await sualtap.post("tournaments", JSON.stringify(obj));
      this.setState({ isLoading: false });
      history.push("/Tournaments");
    }
  };
  setModalVisible(modalVisible) {
    this.setState({ modalVisible });
  }
  componentDidMount = async () => {
    await sualtap.get("organizations").then(res =>
      this.setState({
        organizers: res.data
      })
    );
    this.fetchContinuations();
  };
  fetchContinuations = async () => {
    await sualtap.get("continuations").then(res => {
      this.setState({
        continuations: res.data
      });
    });
  };

  handleContinuation = value => {
    this.setState({ continuation: value });
  };
  handleContinuationChange = e => {
    this.setState({ newContinuation: e.target.value });
  };

  addContinuation = () => {
    if (this.state.newContinuation.length > 3) {
      sualtap
        .post("/continuations", { keyword: this.state.newContinuation.trim() })
        .then(res => {
          this.setState({ newContinuation: "" });
          this.setModalVisible(false);
          this.fetchContinuations();
        });
    } else {
      message.warning("Ad daha uzun olmalıdır");
    }
  };

  handleNewOrganizerChange = e =>
    this.setState({ newOrganizer: e.target.value });

  fetchUser = async value => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    await sualtap.get(`authors?search=${value}`).then(res => {
      if (fetchId !== this.lastFetchId) {
        return;
      }
      this.setState({ usersData: res.data, userFetching: false });
    });
  };

  handleUserChange = value => {
    this.setState({
      userValue: value,
      usersData: [],
      userFetching: false
    });
  };

  render() {
    const { selectedOrganizers, organizers } = this.state;
    const filteredElements = organizers.filter(
      o => !selectedOrganizers.includes(o.name)
    );
    const filteredOrganizers = [];
    this.state.organizers.map(o =>
      this.state.selectedOrganizers.includes(o.id)
        ? filteredOrganizers.push(o.name)
        : null
    );

    const conts = this.state.continuations.map(con => (
      <Option key={con.id} value={con.id}>
        {con.keyword}
      </Option>
    ));

    return (
      <>
        {this.state.isLoading ? <Loader /> : null}
        <div className="b-page-top">
          <h2>Turniri əlavə edin</h2>
          <Button type="primary">
            <Link to="/tournaments">Turnirlər</Link>
          </Button>
        </div>
        <div className="b-add-box">
          <div className="b-add-box-left">
            <div className="checks">
              <Switch
                checkedChildren="Liqa"
                onChange={this.handleIsLeagueChange}
                checked={this.state.newTournament.isLeague}
                unCheckedChildren="Kubok"
                defaultChecked
              />
              <Switch
                checkedChildren="Məşqdir"
                onChange={this.handleIsTrainingChange}
                checked={this.state.isTraining}
                unCheckedChildren="Məşq deyil"
                defaultChecked
              />
              <Switch
                checkedChildren="Yeni təşkilatçı lazımdır"
                onChange={this.handleNeedNewOrganizerChange}
                checked={this.state.needNewOrganizer}
                unCheckedChildren="Yeni təşkilatçı lazım deyil"
                defaultChecked
              />
            </div>
            <Select
              defaultValue={this.state.continuation}
              style={{ width: "100%" }}
              onChange={this.handleContinuation}
            >
              {conts}
            </Select>
            <Row gutter={16}>
              <Col span={18}>
                <Input
                  placeholder="Turnirin adı"
                  value={this.state.newTournament.name}
                  allowClear
                  onChange={e => this.handleNameChange(e)}
                />
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Yaş"
                  value={this.state.age_group}
                  onChange={e => this.handleAgeChange(e)}
                />
              </Col>
            </Row>
            <TextArea
              value={this.state.newTournament.information}
              onChange={e => this.handleInfoChange(e)}
              placeholder="Turnir haqqında ümumi məlumat daxil edin"
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
            <Select
              mode="multiple"
              placeholder="Təşkilatçı qurumlar"
              value={this.state.selectedOrganizers}
              onChange={this.handleChange}
              style={{ width: "100%" }}
            >
              {filteredElements.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              mode="multiple"
              labelInValue
              value={this.state.userValue}
              placeholder="Təşkilatçı şəxslər"
              notFoundContent={
                this.state.userFetching ? <Spin size="small" /> : null
              }
              filterOption={false}
              onSearch={this.fetchUser}
              onChange={this.handleUserChange}
              style={{ width: "100%" }}
            >
              {this.state.usersData.map(d => (
                <Option key={d.id}>{d.fullname}</Option>
              ))}
            </Select>
            {this.state.needNewOrganizer ? (
              <Input
                placeholder="Siyahıda olmayan təşkilatçı"
                value={this.state.newOrganizer}
                allowClear
                onChange={e => this.handleNewOrganizerChange(e)}
              />
            ) : null}
            <Button
              className="btn btn-continue"
              type="primary"
              block
              onClick={this.handleSubmit}
            >
              Əlavə et
            </Button>
          </div>
          <div className="b-add-box-right">
            <div className="b-section">
              <label>Ad</label>
              <p>{this.state.newTournament.name}</p>
            </div>
            <div className="b-section">
              <label>Ətraflı məlumat</label>
              <p>{this.state.newTournament.information}</p>
            </div>
            <div className="b-section">
              <label>
                {this.state.selectedOrganizers.length > 1
                  ? "Təşkilatçılar"
                  : "Təşkilatçı"}
              </label>
              <p>{filteredOrganizers.join(", ")}</p>
            </div>
          </div>
        </div>
        {this.props.user > 3 ? (
          <div onClick={() => this.setModalVisible(true)} className="cont-box">
            <Icon type="caret-up" />
          </div>
        ) : null}
        <Modal
          title="Davamlılıq əlavə et"
          cancelText="Ləğv et"
          okText="Əlavə et"
          centered
          visible={this.state.modalVisible}
          onOk={this.addContinuation}
          onCancel={() => this.setModalVisible(false)}
        >
          <label>Açar söz</label>
          <Input
            value={this.state.newContinuation}
            onChange={this.handleContinuationChange}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user: user.data.role
  };
};
export default connect(mapStateToProps)(Tournament);
