import React from "react";
import { Modal, Input, Button, message } from "antd";
import Phase from "./../Elements/Phase";
import sualtap from "./../../const/api";
import { connect } from "react-redux";
import { loadingOn, loadingOff } from "./../../redux/actions";
import "./style/pages.css";
import history from "./../../const/history";
const { TextArea } = Input;

class Phases extends React.Component {
  state = {
    modalVisible: false,
    phases: [],
    newPhase: "",
    phaseInfo: "",
    showSubs: false,
    visiblePhase: null,
    parentId: null,
    package: "",
    tournament: "",
    cont: "",
    with_theme: false
  };

  setModalVisible = (modalVisible, id) => {
    this.setState({ modalVisible, parentId: id });
  };

  handlePhaseChange = e => this.setState({ newPhase: e.target.value });
  handlePhaseInfoChange = e => this.setState({ phaseInfo: e.target.value });

  fetchPhases = async () => {
    await sualtap.get(`/phases?package=${this.props.params.id}`).then(res => {
      this.setState({ phases: res.data, showSubs: false });
    });
    await sualtap.get(`packages/${this.props.params.id}`).then(res => {
      if (res.data.user_ID !== this.props.user.user_ID) {
        history.push("/my");
      }
      this.setState({
        package: res.data.name,
        with_theme: res.data.game_type.with_Theme
      });
      sualtap.get(`tournaments/${res.data.tournament_ID}`).then(res => {
        if (res.data.isLeague) {
          this.setState({ cont: res.data.continuation ? res.data.continuation.keyword : null });
        }
        this.setState({ tournament: res.data.name });
      });
    });
    this.props.loadingOff();
  };

  componentDidMount() {
    this.props.loadingOn();
    this.fetchPhases();
  }

  openPhase = index => {
    this.setState({ visiblePhase: index, showSubs: true });
  };

  addPhase = async () => {
    if (this.state.newPhase.trim().length === 0) {
      message.warning("Tur adı boş olmamalıdır");
    } else {
      const obj = {
        Package_ID: parseInt(this.props.params.id),
        Parent_ID: this.state.parentId,
        Name: this.state.newPhase,
        Information: this.state.phaseInfo.length === 0 ? null : this.state.phaseInfo
      };
      await sualtap.post("phases", JSON.stringify(obj));
      this.fetchPhases();
      this.setState({ modalVisible: false, newPhase: "", phaseInfo: "" });
    }
  };

  showMains = () => {
    this.setState({ showSubs: false, parentId: null });
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>
            {this.state.cont ? `${this.state.cont} / ` : null}
            {this.state.tournament}
            {this.state.package !== null
              ? ` / ${this.state.package}`
              : null}{" "}
            {this.state.showSubs ? (
              <span>/ {this.state.phases[this.state.visiblePhase].name}</span>
            ) : (
                ""
              )}
            {this.state.showSubs ? (
              <>
                <span> / </span>
                <Button type="primary" onClick={this.showMains}>
                  Əsas etaplara qayıt
                </Button>
              </>
            ) : (
                ""
              )}
          </h2>
        </div>
        <div className="phases">
          {!this.state.showSubs ? (
            <>
              <div
                className="phase flex center"
                onClick={() => this.setModalVisible(true, null)}
              >
                Yeni tur əlavə et
              </div>
              {this.state.phases.map((phase, index) => {
                return (
                  <Phase
                    rewind={this.fetchPhases}
                    value={index}
                    with_theme={this.state.with_theme}
                    showPhase={this.openPhase}
                    openModal={this.setModalVisible}
                    key={phase.id}
                    phase={phase}
                    sub={false}
                  />
                );
              })}
            </>
          ) : (
              <>
                <div
                  className="phase flex center"
                  onClick={() =>
                    this.setModalVisible(
                      true,
                      this.state.phases[this.state.visiblePhase].id
                    )
                  }
                >
                  Yeni alt tur əlavə et
              </div>
                {this.state.phases[this.state.visiblePhase].subs.map(phase => {
                  return (
                    <Phase
                      rewind={this.fetchPhases}
                      with_theme={this.state.with_theme}
                      value={null}
                      key={phase.id}
                      phase={phase}
                      sub={true}
                    />
                  );
                })}
              </>
            )}
        </div>
        <Modal
          title={this.state.parentId ? "Alt etap əlavə et" : "Etap əlavə et"}
          cancelText="Ləğv et"
          okText="Əlavə et"
          centered
          visible={this.state.modalVisible}
          onOk={this.addPhase}
          onCancel={() => this.setModalVisible(false)}
        >
          <Input
            placeholder="Turun adı"
            value={this.state.newPhase}
            onChange={this.handlePhaseChange}
          />
          <TextArea
            placeholder="Tur öncəsi qeyd"
            value={this.state.phaseInfo}
            onChange={this.handlePhaseInfoChange}
          ></TextArea>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = ({ user }) => {
  return {
    user: user.data
  };
};

export default connect(mapStateToProps, { loadingOn, loadingOff })(Phases);
