import React from "react";
import { Table, Button } from "antd";
import { Link } from 'react-router-dom';
import sualtap from './../../../const/api';
import { connect } from 'react-redux';
import { loadingOff, loadingOn } from './../../../redux/actions';

const columns = [
    {
        title: "Adı",
        dataIndex: "name",
        key: "name",
        render: text => <a href="/">{text}</a>
    },
    {
        title: "Şəhər",
        dataIndex: "city",
        key: "city",
        render: text => <span>{text}</span>
    },
    {
        title: "Sədr",
        dataIndex: "person",
        key: "person"
    },
    {
        title: "Telefon",
        dataIndex: "phone",
        key: "phone"
    },
    {
        title: "Məlumat",
        dataIndex: "information",
        key: "information",
        render: text => text !== null ? text.substr(0, 40) + "..." : null
    }
];

class Organizations extends React.Component {
    state = {
        organizations: [],
    };

    fetchOrganizations = async () => {
        await sualtap
            .get("organizations")
            .then(res => this.setState({
                organizations: res.data
            }));
        setTimeout(() => {
            this.props.loadingOff();
        }, 500)
    }

    componentDidMount() {
        this.props.loadingOn();
        this.fetchOrganizations();
    }

    render() {
        const dataSource = this.state.organizations.map(organization => ({
            key: organization.id,
            ...organization
        }));

        return (
            <React.Fragment>
                <div className="b-page-top">
                    <h2>Təşkilatlar</h2>
                    <Button type="primary"><Link to="/organizations/new">Təşkilat əlavə et</Link></Button>
                </div>
                <div className="floater">
                    <Table columns={columns} dataSource={dataSource} />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(null, { loadingOff, loadingOn })(Organizations);
