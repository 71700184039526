import React from "react";
import { Table, Button, message } from "antd";
import sualtap from "./../../const/api";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Role",
    dataIndex: "role",
  },
];

class Roles extends React.Component {
  state = {
    roles: [],
    newRoleValue: "",
    newRoleName: "",
  };

  fetchRoles() {
    sualtap.get("user_roles").then((res) =>
      this.setState({
        roles: res.data.map((re) => {
          return {
            key: re.id,
            ...re,
          };
        }),
      })
    );
  }

  componentDidMount() {
    this.fetchRoles();
  }
  handleValueChange = (e) => {
    this.setState({
      newRoleValue: e.target.value,
    });
  };
  handleNameChange = (e) => {
    this.setState({
      newRoleName: e.target.value,
    });
  };

  addRole = async (e) => {
    e.preventDefault();
    if (
      !this.state.newRoleValue.trim().length ||
      !this.state.newRoleName.trim().length
    ) {
      message.warning("Rolun adını və ya dəyəri boş buraxıla bilməz");
    } else {
      await sualtap.post(
        "user_roles",
        JSON.stringify({
          role: parseInt(this.state.newRoleValue),
          name: this.state.newRoleName,
        })
      );
      message.success("İstifadəçi rolu əlavə edildi");
      this.fetchRoles();
      this.setState({ newRoleValue: "", newRoleName: "" });
    }
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>İstifadəçi rolları</h2>
        </div>
        <div className="b-add-box">
          <div className="b-add-box-left">
            <Table
              columns={columns}
              dataSource={this.state.roles}
              size="middle"
            />
          </div>
          <div className="b-add-box-right">
            <form onSubmit={this.addRole}>
              <label>Dəyər</label>
              <input
                value={this.state.newRoleValue}
                onChange={this.handleValueChange}
                className="ant-input"
                type="number"
              />
              <label>Rolun adı</label>
              <input
                value={this.state.newRoleName}
                onChange={this.handleNameChange}
                className="ant-input"
                type="text"
              />
              <Button htmlType="submit" type="primary">
                Əlavə et
              </Button>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Roles;
