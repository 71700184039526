import React from "react";
import {
  Input,
  InputNumber,
  Button,
  message
} from "antd";
import sualtap from "../../../const/api";

class Phase extends React.Component {
  state = {
    phaseID: 1,
    phase: {}
  };

  fetchPhase = async () => {
    await sualtap.get(`phases/${this.state.phaseID}`).then(res => {
      this.setState({
        phase: res.data
      });
    });
  };

  handlePhaseName = e => {
    const phase = { ...this.state.phase };
    phase.name = e.target.value;
    this.setState({
      phase
    });
  };
  handlePhaseID = e => {
    this.setState({ phaseID: e });
  };

  changePackage = async () => {
    await sualtap
      .put(`phases/${this.state.phase.id}`, this.state.phase)
      .then(res => {
        message.success("Dəyişiklik edildi");
        this.setState({
          phase: {}
        });
      });
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Tur</h2>
        </div>
        <div className="b-add-box">
          <div className="b-add-box-left">
            <div className="b-100">
              <label>Phase</label>
              <InputNumber
                value={this.state.phaseID}
                onChange={e => this.handlePhaseID(e)}
                type="number"
              />
              <Button onClick={this.fetchPhase} type="primary">
                Turu gətir
              </Button>
            </div>
            {this.state.phase.id ? (
              <>
                <div className="b-100">
                  <Input
                    value={this.state.phase.name}
                    onChange={e => this.handlePhaseName(e)}
                  />
                </div>
                <div className="b-100">
                  <Button onClick={this.changePackage} type="primary">
                    Dəyişiklik et
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default Phase;
