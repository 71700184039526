import React from "react";
import { Table, Tag, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import sualtap from "../../../const/api";
import Loader from "./../../Elements/Loader";
import { connect } from "react-redux";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Adı",
    dataIndex: "package",
    key: "name",
    render: ({ id, name }) => (
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={`https://3sual.az/Package/${id}`}
      >
        {name}
      </a>
    )
  },
  {
    title: "Növü",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    render: tags => (
      <span>
        {tags.slice(0, 2).map(tag => {
          let color = tag.length > 5 ? "geekblue" : "green";
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    )
  },
  {
    title: "Əməliyyat",
    key: "action",
    render: ({ id }) => (
      <span>
        <Link to={`/package/${id}/phases`}>Turlara bax</Link>
      </span>
    )
  },
  {
    title: "Hazırdır",
    dataIndex: "ready",
    key: "ready"
  }
];

const ncolumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Adı",
    dataIndex: "package",
    key: "name",
    render: ({ id, name }) => (
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={`https://3sual.az/Package/${id}`}
      >
        {name}
      </a>
    )
  },
  {
    title: "Növü",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    render: tags => (
      <span>
        {tags.slice(0, 2).map(tag => {
          let color = tag.length > 5 ? "geekblue" : "green";
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    )
  },
  {
    title: "Turnir",
    key: "tournament",
    render: ({ tournament }) => (
      <span>
        {tournament.isLeague && tournament.continuation != null ? (
          <span>
            {tournament.continuation.keyword + " / " + tournament.name}
          </span>
        ) : (
          <span>{tournament.name}</span>
        )}
      </span>
    )
  },
  {
    title: "Əməliyyat",
    key: "action",
    render: ({ id }) => (
      <span>
        <Link to={`/package/${id}/phases`}>Turlara bax</Link>
      </span>
    )
  },
  {
    title: "Hazırdır",
    dataIndex: "ready",
    key: "ready"
  }
];

function cancel() {
  console.log("Cancelll");
}

class Packages extends React.Component {
  state = {
    packages: [],
    tournament: {},
    isLoading: true
  };
  componentDidMount = () => {
    this.fetchPackages();
  };
  fetchPackages = async () => {
    if (!this.props.user) {
      await sualtap.get(`/tournaments/${this.props.params.id}`).then(res => {
        this.setState({ tournament: res.data, packages: res.data.packages });
      });
    } else {
      await sualtap
        .get(`/packages/my?id=${this.props.userData.user_ID}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userData.token}`
          }
        })
        .then(res => {
          this.setState({ packages: res.data });
        });
    }
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500);
  };

  activatePackage = async e => {
    await sualtap
      .post(`packages/activate?id=${e.id}`)
      .then(res => console.log(res));
    this.fetchPackages();
  };

  render() {
    const data = [];
    if (this.props.user) {
      this.state.packages.map(pack =>
        data.push({
          key: pack.id,
          package: pack,
          id: pack.id,
          type: pack.type,
          name: pack.name,
          tags: pack.tags.map(tage => tage.name),
          tournament: pack.tournament,
          ready: !pack.isReady ? (
            <Popconfirm
              title="Paketin hazır olduğundan əminsiniz?"
              onConfirm={this.activatePackage.bind(this, pack)}
              onCancel={cancel}
              okText="Bəli"
              cancelText="Xeyr"
            >
              <span>Paketi paylaş</span>
            </Popconfirm>
          ) : null
        })
      );
    }
    if (!this.props.user) {
      this.state.packages.map(pack =>
        data.push({
          key: pack.id,
          package: pack,
          id: pack.id,
          type: pack.game,
          name: pack.name,
          tags: pack.tags.map(tage => tage.name),
          tournament: pack.tournament,
          ready: !pack.isReady ? (
            <Popconfirm
              title="Paketin hazır olduğundan əminsiniz?"
              onConfirm={this.activatePackage.bind(this, pack)}
              onCancel={cancel}
              okText="Bəli"
              cancelText="Xeyr"
            >
              <span>Paketi paylaş</span>
            </Popconfirm>
          ) : null
        })
      );
    }
    const loader = this.state.isLoading ? <Loader /> : null;
    return (
      <>
        {loader}
        <div className="b-page-top">
          {!this.props.user ? (
            <h2>
              {this.state.tournament.isLeague &&
              this.state.tournament.continuation
                ? `${this.state.tournament.continuation.keyword} / `
                : null}
              {this.state.tournament.name}
            </h2>
          ) : (
            <h2>Əlavə etdiyim paketlər</h2>
          )}
        </div>
        {this.props.user ? (
          <Table columns={ncolumns} dataSource={data} />
        ) : (
          <Table columns={columns} dataSource={data} />
        )}
      </>
    );
  }
}
const mapStateToProps = ({ user }) => {
  return {
    userData: user.data
  };
};

export default connect(mapStateToProps)(Packages);
