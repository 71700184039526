import React from "react";
import { Table, Button, Switch, message } from "antd";
import sualtap from "../../const/api";

const columns = [
  {
    title: "ID",
    dataIndex: "id"
  },
  {
    title: "Name",
    dataIndex: "name"
  },
  {
    title: "Mövzu",
    dataIndex: "with_Theme",
    render: val => val ? 'Bəli' : 'Xeyr'
  },
  {
    title: "Komanda",
    dataIndex: "with_Team",
    render: val => val ? 'Bəli' : 'Xeyr'
  }
];

class Games extends React.Component {
  state = {
    games: [],
    newGameName: "",
    withTheme: false,
    withTeam: false
  };

  fetchGames() {
    sualtap.get("game_type").then(res =>
      this.setState({
        games: res.data.map(re => {
          return {
            key: re.id,
            ...re
          };
        })
      })
    );
  }

  componentDidMount() {
    this.fetchGames();
  }

  handleNameChange = e => {
    this.setState({
      newGameName: e.target.value
    });
  };
  onSwitchTeamChange = checked => {
    this.setState({ withTeam: checked });
  };
  onSwitchThemeChange = checked => {
    this.setState({ withTheme: checked });
  };
  addGame = async e => {
    e.preventDefault();
    if (!this.state.newGameName.trim().length) {
      message.warning("Oyunun adını daxil edin");
    }
    else {
      await sualtap.post(
        "game_type",
        JSON.stringify({
          with_theme: this.state.withTheme,
          with_team: this.state.withTeam,
          name: this.state.newGameName
        })
      );
      message.success("Oyun növü əlavə edildi");
      this.fetchGames();
      this.setState({
        withTeam: false,
        withTheme: false,
        name: ""
      })
    }
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Oyun növləri</h2>
        </div>
        <div className="b-add-box">
          <div className="b-add-box-left">
            <Table
              columns={columns}
              dataSource={this.state.games}
              size="middle"
            />
          </div>
          <div className="b-add-box-right">
            <form onSubmit={this.addGame}>
              <label>Oyunun adı</label>
              <input
                value={this.state.newGameName}
                onChange={this.handleNameChange}
                className="ant-input"
                type="text"
              />
              <div className="b-switch-boxes">
                <div className="b-switch-box">
                  <label>Komanda ilə</label>
                  <Switch
                    checked={this.state.withTeam}
                    onChange={this.onSwitchTeamChange}
                  />
                </div>
                <div className="b-switch-box">
                  <label>Mövzu ilə</label>
                  <Switch
                    checked={this.state.withTheme}
                    onChange={this.onSwitchThemeChange}
                  />
                </div>
              </div>
              <div className="fl w-100">
                <Button htmlType="submit" type="primary">
                  Əlavə et
                </Button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default Games;
