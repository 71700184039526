import React, { useEffect, useState } from "react";
import { Statistic, Row, Col, Card, Icon } from "antd";
import sualtap from "../../const/api";
import { connect } from "react-redux";
import { loadingOff, loadingOn } from "./../../redux/actions";

const Statistics = props => {
  const [stats, setStats] = useState({});

  useEffect(() => {
    const fetchStats = async () => {
      props.loadingOn();
      await sualtap.get("info/stats").then(res => {
        console.log(res.data);
        setStats(res.data);
      });
      // sualtap.get('packages/preparing').then(res => console.log(res));
      props.loadingOff();
    }
    fetchStats();
  }, [props]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Card>
          <Statistic
            title="Tək suallar"
            value={stats.nhn}
            valueStyle={{ color: "#3f8600" }}
            prefix={<Icon type="arrow-up" />}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Mövzular"
            value={stats.theme}
            valueStyle={{ color: "#3f8600" }}
            prefix={<Icon type="arrow-up" />}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Oyun növləri"
            value={stats.games}
            valueStyle={{ color: "#3f8600" }}
            prefix={<Icon type="arrow-up" />}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Turnirlər"
            value={stats.tournaments}
            valueStyle={{ color: "#3f8600" }}
            prefix={<Icon type="arrow-up" />}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Hazır paketlər"
            value={stats.packages}
            valueStyle={{ color: "#3f8600" }}
            prefix={<Icon type="arrow-up" />}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Müəlliflər"
            value={stats.authors}
            valueStyle={{ color: "#3f8600" }}
            prefix={<Icon type="arrow-up" />}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Təşkilatlar"
            value={stats.organizations}
            valueStyle={{ color: "#3f8600" }}
            prefix={<Icon type="arrow-up" />}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Xəmsə raundu"
            value={stats.xamsa}
            valueStyle={{ color: "#3f8600" }}
            prefix={<Icon type="arrow-up" />}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Breyn rinq"
            value={stats.br}
            valueStyle={{ color: "#3f8600" }}
            prefix={<Icon type="arrow-up" />}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default connect(null, { loadingOn, loadingOff })(Statistics);
