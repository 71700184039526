import React from "react";
import {
  Upload,
  Select,
  Modal,
  Input,
  Radio,
  Button,
  Icon,
  Switch,
  Spin,
  message
} from "antd";
import "./style/types.css";
import sualtap from "./../../../../const/api";
import { connect } from "react-redux";
import { loadingOn, loadingOff } from "./../../../../redux/actions";
import siteUrl from "./../../../../const/const";
const { Option } = Select;

const props2 = {
  listType: "picture",
  className: "upload-list-inline",
  accept: ".png,.jpg,.jpeg"
};

class Type1 extends React.Component {
  constructor(props) {
    super(props);
    this.lastAuthorFetchId = 0;
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      allAuthors: [],
      modalVisible: false,
      newEditor: "",
      selectedAuthors: [],
      authors: [],
      authorsFetching: false,
      totalRekvizit: false,
      type: 1,
      noteBefore: false,
      isTranslated: false,
      isForAll: false,
      allRekvizit: {
        text_rekvizit: false,
        values: {
          rekvizit: null,
          text_rekvizit: null
        }
      },
      questions: [
        {
          rekvizit: false,
          text_rekvizit: false,
          values: {
            rekvizit: null,
            text_rekvizit: null,
            question: "",
            answer: ""
          }
        },
        {
          rekvizit: false,
          text_rekvizit: false,
          values: {
            rekvizit: null,
            text_rekvizit: null,
            question: "",
            answer: ""
          }
        },
        {
          rekvizit: false,
          text_rekvizit: false,
          values: {
            rekvizit: null,
            text_rekvizit: null,
            question: "",
            answer: ""
          }
        }
      ],
      values: {
        comment: "",
        noteBefore: "",
        considered: "",
        answer: "",
        source: "",
        sources: []
      }
    };
  }

  componentDidMount() {
    this.fetchAuthors();
  }
  fetchAuthors = async () => {
    await sualtap.get("authors").then(res => {
      this.setState({
        allAuthors: res.data
      });
    });
  };

  resetState() {
    message.success("Sual uğurla əlavə olundu");
    this.setState(this.getInitialState());
    this.fetchAuthors();
  }
  setModalVisible = val => {
    this.setState({ modalVisible: val });
  };
  handleNewEditor = e => {
    this.setState({ newEditor: e.target.value });
  };
  addEditor = async e => {
    await sualtap
      .post(`authors`, JSON.stringify({ fullname: this.state.newEditor }))
      .then(res => {
        this.setState({
          newEditor: "",
          modalVisible: false
        });
        message.warning("Yeni müəllif əlavə olundu");
        this.fetchAuthors();
      });
  };
  // Authors search
  filterAuthorsSearch = async value => {
    this.lastAuthorFetchId += 1;
    const authorFetchId = this.lastAuthorFetchId;
    this.setState({ authors: [], authorsFetching: true });
    let authores = this.state.allAuthors.filter(a =>
      a.fullname.toLowerCase().includes(value.toLowerCase())
    );
    if (authorFetchId !== this.lastAuthorFetchId) {
      return;
    }
    this.setState({ authors: authores, authorsFetching: false });
  };
  handleAuthorsSearchChange = value => {
    this.setState({
      selectedAuthors: value,
      authors: [],
      authorsFetching: false
    });
  };
  handleTypeChange = e => {
    this.setState({ type: parseInt(e.target.value) });
  };
  handleIsForAllChange = e => {
    if (!this.state.isForAll) {
      const obj = [...this.state.questions];
      obj[0].rekvizit = false;
      obj[1].rekvizit = false;
      obj[2].rekvizit = false;
      this.setState({
        questions: obj
      });
    }
    this.setState({ isForAll: !this.state.isForAll });
  };
  handleIsTranslatedChange() {
    this.setState({ isTranslated: !this.state.isTranslated });
  }
  handleTotalRekvizitChange() {
    const obj = [...this.state.questions];
    this.state.totalRekvizit
      ? (obj[0].rekvizit = false)
      : (obj[0].rekvizit = true);
    obj[1].rekvizit = false;
    obj[2].rekvizit = false;
    this.setState({ questions: obj, isForAll: false });
    this.setState({ totalRekvizit: !this.state.totalRekvizit });
  }
  handleIsRekvizitChange(i) {
    const obj = [...this.state.questions];
    obj[i].rekvizit = !obj[i].rekvizit;
    this.setState({
      questions: obj
    });
    !this.state.questions.filter(q => q.rekvizit).length
      ? this.setState({ totalRekvizit: false })
      : this.setState({ totalRekvizit: true });
  }
  handleIsTextRekvizitChange(i) {
    const obj = [...this.state.questions];
    obj[i].text_rekvizit = !obj[i].text_rekvizit;
    this.setState({
      questions: obj
    });
  }
  handleIsAllTextRekvizitChange() {
    var obj = { ...this.state.allRekvizit };
    obj.text_rekvizit = !obj.text_rekvizit;
    this.setState({
      allRekvizit: obj
    });
  }
  handleTextRekvizitChange = (e, i) => {
    const obj = [...this.state.questions];
    obj[i].values.text_rekvizit = e.target.value;
    this.setState({
      questions: obj
    });
  };
  handleAllTextRekvizitChange = e => {
    var obj = { ...this.state.allRekvizit };
    obj.values.text_rekvizit = e.target.value;
    this.setState({
      allRekvizit: obj
    });
  };
  renderNoteBefore() {
    return this.state.noteBefore ? (
      <textarea
        onChange={e => this.handleNoteBeforeTextChange(e)}
        value={this.state.values.noteBefore}
        placeholder="Qeyd"
      ></textarea>
    ) : null;
  }
  handleNoteBeforeChange() {
    this.setState({
      noteBefore: !this.state.noteBefore
    });
  }
  renderQuestions() {
    let resp = [];
    for (let i = 0; i < this.state.type; i++) {
      resp.push(
        <div key={i} className="single-question">
          {this.state.totalRekvizit && !this.state.isForAll ? (
            <Switch
              className="mt-10"
              checkedChildren="Rekvizit var"
              onChange={() => this.handleIsRekvizitChange(i)}
              unCheckedChildren="Rekvizit yoxdur"
              checked={this.state.questions[i].rekvizit}
            />
          ) : null}
          {this.state.questions[i].rekvizit && !this.state.isForAll ? (
            <Switch
              className="mt-10"
              checkedChildren="Rekvizit mətn"
              onChange={() => this.handleIsTextRekvizitChange(i)}
              unCheckedChildren="Rekvizit foto"
              checked={this.state.questions[i].text_rekvizit}
            />
          ) : null}
          {this.state.type > 1 ? (
            <div className="question-number">{i + 1}</div>
          ) : null}
          {this.state.questions[i].text_rekvizit &&
          this.state.questions[i].rekvizit ? (
            <textarea
              value={this.state.questions[i].values.text_rekvizit}
              onChange={e => this.handleTextRekvizitChange(e, i)}
              placeholder="Rekvizitin mətnini daxil edin"
            ></textarea>
          ) : null}
          <textarea
            placeholder="Sualın mətnini daxil edin"
            onChange={e => this.handleQuestionTextChange(e, i)}
            value={this.state.questions[i].values.question}
          ></textarea>
          <input
            type="text"
            value={this.state.questions[i].values.answer}
            onChange={e => this.handleAnswerChange(e, i)}
            placeholder="Sualın cavabı"
          />
        </div>
      );
    }
    return resp.map(a => a);
  }
  handleQuestionTextChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].values.question = e.target.value;
    this.setState({
      questions: obj
    });
  }
  handleCommentChange(e) {
    const obj = this.state.values;
    obj.comment = e.target.value;
    this.setState({
      values: obj
    });
  }
  handleAnswerChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].values.answer = e.target.value;
    this.setState({
      questions: obj
    });
  }
  handleConsideredChange(e) {
    const obj = this.state.values;
    obj.considered = e.target.value;
    this.setState({
      values: obj
    });
  }
  handleNoteBeforeTextChange(e) {
    const obj = this.state.values;
    obj.noteBefore = e.target.value;
    this.setState({
      values: obj
    });
  }
  handleSourceChange(e) {
    const obj = this.state.values;
    obj.source = e.target.value;
    obj.sources =
      e.target.value.trim() !== "" ? e.target.value.trim().split("/-/") : [];
    this.setState({
      values: obj
    });
  }
  handleUploadChange(e, i) {
    let obj = [...this.state.questions];
    obj[i].values.rekvizit = null;
    this.setState({
      questions: obj
    });
  }
  handleAllUploadChange(e) {
    let obj = { ...this.state.allRekvizit };
    obj.values.rekvizit = null;
    this.setState({
      allRekvizit: obj
    });
  }
  uploadAllImage(e) {
    let form_data = new FormData();
    const filename = Math.random(1, 999999) + Date.now() + e.file.name;
    form_data.append("image", e.file, filename);
    sualtap
      .post("questions/rekvizit", form_data, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
      .then(res => {
        const obj = { ...this.state.allRekvizit };
        obj.values.rekvizit = res.data;
        this.setState({
          allRekvizit: obj
        });
      })
      .catch(err => console.log(err));
  }
  uploadImage(e, i) {
    let form_data = new FormData();
    const filename = Math.random(1, 999999) + Date.now() + e.file.name;
    form_data.append("image", e.file, filename);
    sualtap
      .post("questions/rekvizit", form_data, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
      .then(res => {
        const obj = [...this.state.questions];
        obj[i].values.rekvizit = res.data;
        this.setState({
          questions: obj
        });
      })
      .catch(err => console.log(err));
  }
  renderUploadButtons() {
    let resp = [];
    for (let i = 0; i < this.state.type; i++) {
      if (
        this.state.questions[i].rekvizit &&
        !this.state.questions[i].text_rekvizit
      ) {
        resp.push(
          <div className="rekvizit_Uploader" key={i}>
            {this.state.type > 1 ? <p className="upload-btn">{i + 1}</p> : null}
            <Upload
              {...props2}
              onChange={e => this.handleUploadChange(e, i)}
              customRequest={e => this.uploadImage(e, i)}
            >
              <Button>
                <Icon type="upload" /> Upload
              </Button>
            </Upload>
          </div>
        );
      }
    }
    return resp.length ? (
      <React.Fragment>
        <div className="rekvizit_Uploaders">{resp.map(a => a)}</div>
        <div className="b-section-seperator"></div>
      </React.Fragment>
    ) : null;
  }
  renderQuestionType() {
    switch (this.state.type) {
      case 2:
        return "Duplet";
      case 3:
        return "Blitz";
      default:
        return null;
    }
  }
  submitQuestion = () => {
    if (this.validateQuestion()) {
      this.props.loadingOn();
      sualtap
        .post(
          "questions/nhn",
          JSON.stringify({
            ...this.state,
            isTranslated:
              this.state.selectedAuthors.map(s => parseInt(s.key)).length ===
                0 && this.props.isNotOriginal
                ? true
                : this.state.isTranslated,
            authors: this.state.selectedAuthors.map(s => parseInt(s.key)),
            phase: parseInt(this.props.params.id)
          })
        )
        .then(res => {
          this.props.loadingOff();
          this.resetState();
          this.props.fetch();
        });
    }
  };
  isNullOrEmpty(str) {
    return str === null || str.trim().length === 0 ? true : false;
  }
  validateQuestion = () => {
    let validated = true;
    const firstText = this.state.type > 1 ? "1-ci sualın " : "Sualın ";
    if (this.state.questions[0].values.question.trim().length < 10) {
      message.warning(firstText + "mətnini daxil edin");
      validated = false;
    }
    if (this.state.questions[0].values.answer.trim().length < 1) {
      message.warning(firstText + "cavabını daxil edin");
      validated = false;
    }
    if (
      this.state.questions[0].rekvizit &&
      !this.state.questions[0].text_rekvizit &&
      this.isNullOrEmpty(this.state.questions[0].values.rekvizit)
    ) {
      message.warning(firstText + "rekvizit fotosunu daxil edin");
      validated = false;
    } else if (
      this.state.questions[0].text_rekvizit &&
      this.isNullOrEmpty(this.state.questions[0].values.text_rekvizit)
    ) {
      message.warning(firstText + "rekvizit mətnini daxil edin");
      validated = false;
    }
    if (this.state.type === 2) {
      if (this.state.isForAll) {
        if (
          this.state.allRekvizit.text_rekvizit &&
          this.isNullOrEmpty(this.state.allRekvizit.values.text_rekvizit)
        ) {
          message.warning("Rekvizitin mətnini daxil edin");
        }
        if (
          !this.state.allRekvizit.text_rekvizit &&
          this.isNullOrEmpty(this.state.allRekvizit.values.rekvizit)
        ) {
          message.warning("Rekvizit fotosunu seçin");
        }
      }
      if (this.state.questions[1].values.question.trim().length < 10) {
        message.warning("2-ci sualın mətnini daxil edin");
        validated = false;
      }
      if (this.state.questions[1].values.answer.trim().length < 1) {
        message.warning("2-ci sualın cavabını daxil edin");
        validated = false;
      }
      if (
        this.state.questions[1].rekvizit &&
        !this.state.questions[1].text_rekvizit &&
        this.isNullOrEmpty(this.state.questions[1].values.rekvizit)
      ) {
        message.warning("2-ci sualın rekvizit fotosunu daxil edin");
        validated = false;
      } else if (
        this.state.questions[1].text_rekvizit &&
        this.isNullOrEmpty(this.state.questions[1].values.text_rekvizit)
      ) {
        message.warning("2-ci sualın rekvizit mətnini daxil edin");
        validated = false;
      }
    }
    if (this.state.type === 3) {
      if (this.state.questions[1].values.question.trim().length < 10) {
        message.warning("2-ci sualın mətnini daxil edin");
        validated = false;
      }
      if (this.state.questions[1].values.answer.trim().length < 1) {
        message.warning("2-ci sualın cavabını daxil edin");
        validated = false;
      }
      if (
        this.state.questions[1].rekvizit &&
        !this.state.questions[1].text_rekvizit &&
        this.isNullOrEmpty(this.state.questions[1].values.rekvizit)
      ) {
        message.warning("2-ci sualın rekvizit fotosunu daxil edin");
        validated = false;
      } else if (
        this.state.questions[1].text_rekvizit &&
        this.isNullOrEmpty(this.state.questions[1].values.text_rekvizit)
      ) {
        message.warning("2-ci sualın rekvizit mətnini daxil edin");
        validated = false;
      }

      if (this.state.questions[2].values.question.trim().length < 10) {
        message.warning("3-cü sualın mətnini daxil edin");
        validated = false;
      }
      if (this.state.questions[2].values.answer.trim().length < 1) {
        message.warning("3-cü sualın cavabını daxil edin");
        validated = false;
      }
      if (
        this.state.questions[2].rekvizit &&
        !this.state.questions[2].text_rekvizit &&
        this.isNullOrEmpty(this.state.questions[2].values.rekvizit)
      ) {
        message.warning("3-cü sualın rekvizit fotosunu daxil edin");
        validated = false;
      } else if (
        this.state.questions[2].text_rekvizit &&
        this.isNullOrEmpty(this.state.questions[2].values.text_rekvizit)
      ) {
        message.warning("3-cü sualın rekvizit mətnini daxil edin");
        validated = false;
      }
    }
    return validated;
  };

  render() {
    return (
      <>
        <div className="b-add-box">
          <div className="b-add-box-left bg-white">
            <div className="b-type-radios-4">
              <Radio.Group
                defaultValue={this.state.type.toString()}
                value={this.state.type.toString()}
                buttonStyle="solid"
                onChange={this.handleTypeChange}
              >
                <Radio.Button value="1">Adi</Radio.Button>
                <Radio.Button value="2">Duplet</Radio.Button>
                <Radio.Button value="3">Blitz</Radio.Button>
              </Radio.Group>
            </div>
            {/* <div className="b-section-seperator"></div> */}
            {!this.props.isNotOriginal ? (
              <Switch
                className="mt-10"
                checkedChildren="Sual tərcümədir"
                onChange={() => this.handleIsTranslatedChange()}
                unCheckedChildren="Sual originaldır"
                checked={this.state.isTranslated}
              />
            ) : null}
            <Switch
              className="mt-10"
              checkedChildren="Qeyd var"
              onChange={() => this.handleNoteBeforeChange()}
              unCheckedChildren="Qeyd yoxdur"
              checked={this.state.noteBefore}
            />
            <Switch
              className="mt-10"
              checkedChildren="Sualda rekvizit var"
              onChange={() => this.handleTotalRekvizitChange()}
              unCheckedChildren="Sualda rekvizit yoxdur"
              checked={this.state.totalRekvizit}
            />
            {this.state.totalRekvizit && this.state.type > 1 ? (
              <Switch
                className="mt-10"
                checkedChildren="Bütün suallar üçün bir rekvizit"
                onChange={() => this.handleIsForAllChange()}
                unCheckedChildren="Hər sual üçün ayrı rekvizit"
                checked={this.state.isForAll}
              />
            ) : null}
            {this.renderNoteBefore()}
            {this.state.isForAll ? (
              <Switch
                className="mt-10"
                checkedChildren="Rekvizit mətn"
                onChange={() => this.handleIsAllTextRekvizitChange()}
                unCheckedChildren="Rekvizit foto"
                checked={this.state.allRekvizit.text_rekvizit}
              />
            ) : null}
            {this.state.allRekvizit.text_rekvizit && this.state.isForAll ? (
              <textarea
                value={this.state.allRekvizit.values.text_rekvizit}
                onChange={e => this.handleAllTextRekvizitChange(e)}
                placeholder="Rekvizitin mətnini daxil edin"
              ></textarea>
            ) : null}
            {!this.state.allRekvizit.text_rekvizit && this.state.isForAll ? (
              <div className="rekvizit_Uploaders">
                <Upload
                  {...props2}
                  onChange={e => this.handleAllUploadChange(e)}
                  customRequest={e => this.uploadAllImage(e)}
                >
                  <Button>
                    <Icon type="upload" /> Upload
                  </Button>
                </Upload>
              </div>
            ) : null}
            {this.renderQuestions()}
            {/* <div className="b-section-seperator"></div> */}
            {!this.state.isForAll ? this.renderUploadButtons() : null}
            {/* {this.renderUploadButtons()} */}
            <div className="question-details">
              <input
                type="text"
                value={this.state.values.considered}
                onChange={e => this.handleConsideredChange(e)}
                placeholder="Sayılma meyarı"
              />
              <textarea
                value={this.state.values.comment}
                onChange={e => this.handleCommentChange(e)}
                placeholder="Sualın şərhi"
              ></textarea>
              <input
                type="text"
                value={this.state.values.source}
                onChange={e => this.handleSourceChange(e)}
                placeholder="Mənbə"
              />
              <Select
                mode="multiple"
                labelInValue
                value={this.state.selectedAuthors}
                placeholder="Müəllifləri seç"
                notFoundContent={
                  this.state.authorsFetching ? <Spin size="small" /> : null
                }
                filterOption={false}
                onSearch={this.filterAuthorsSearch}
                onChange={this.handleAuthorsSearchChange}
                style={{ width: "100%" }}
              >
                {this.state.authors.map(a => (
                  <Option key={a.id}>{a.fullname}</Option>
                ))}
              </Select>
              {/* <div className="b-section-seperator"></div> */}
            </div>
            {/* <div className="b-level-radios mt-10 w-100">
                            <Radio.Group
                                defaultValue={this.state.level.toString()}
                                buttonStyle="solid"
                                onChange={this.handleLevelChange}
                            >
                                <Radio.Button value="1">1</Radio.Button>
                                <Radio.Button value="2">2</Radio.Button>
                                <Radio.Button value="3">3</Radio.Button>
                                <Radio.Button value="4">4</Radio.Button>
                                <Radio.Button value="5">5</Radio.Button>
                            </Radio.Group>
                        </div> */}
          </div>
          <div className="b-add-box-right bg-white">
            <div className="b-question-item">
              <h3>
                <b>Sual nömrə: {this.props.number}</b>
              </h3>
              <span className="font-20">{this.renderQuestionType()}</span>
            </div>
            {this.state.values.noteBefore.trim() ? (
              <div className="b-question-item b-answer">
                {/* <span>Sualöncəsi qeyd: </span> */}
                {this.state.values.noteBefore}
              </div>
            ) : null}
            {this.state.isForAll ? (
              <div className="b-question-item b-answer">
                <p>
                  <b>Rekvizit</b>
                </p>
                {this.state.allRekvizit.text_rekvizit &&
                this.state.allRekvizit.values.text_rekvizit !== null ? (
                  <p className="b-question-rek-text">
                    {this.state.allRekvizit.values.text_rekvizit
                      .split("/-/")
                      .map((m, index) => {
                        return (
                          <span key={index}>
                            {m}
                            <br />
                          </span>
                        );
                      })}
                  </p>
                ) : null}
                {!this.state.allRekvizit.text_rekvizit &&
                this.state.allRekvizit.values.rekvizit !== null ? (
                  <div className="b-question-content">
                    <img
                      alt={this.state.allRekvizit.values.rekvizit}
                      src={`${siteUrl}images/temp/${this.state.allRekvizit.values.rekvizit}`}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {this.state.questions.map(q => {
              const key = this.state.questions.indexOf(q) + 1;
              const img = `${siteUrl}images/temp/` + q.values.rekvizit;
              const rekOrNot = q.values.rekvizit != null && q.rekvizit;
              const rekTextOrNot =
                q.values.text_rekvizit !== null &&
                q.text_rekvizit &&
                q.values.text_rekvizit.trim() !== "";
              const rekword = rekOrNot || rekTextOrNot ? "Rekvizit" : null;
              const floatorNot = rekOrNot || rekTextOrNot ? "unset" : "left";

              const main = q.values.question.split("/-/");
              let towrite = main.map((m, index) => {
                return (
                  <span key={index}>
                    {index ? <span>&nbsp;&nbsp;</span> : null}
                    {m}
                    <br />
                  </span>
                );
              });

              let gettowrite = main.map((m, index) => {
                return (
                  <span key={index}>
                    {m}
                    <br />
                  </span>
                );
              });

              let rektowrite = null;
              if (rekTextOrNot) {
                const rekmain = q.values.text_rekvizit.split("/-/");
                rektowrite = rekmain.map((m, index) => {
                  return (
                    <span key={index}>
                      {m}
                      <br />
                    </span>
                  );
                });
              }

              return key <= this.state.type ? (
                <div key={key} className="b-question-content">
                  <p style={{ float: floatorNot }}>
                    {this.state.type !== 1 ? (
                      <span>{`${key}.`}&nbsp;</span>
                    ) : null}
                    {rekword}
                  </p>
                  {rekTextOrNot ? (
                    <p className="b-question-rek-text">{rektowrite}</p>
                  ) : null}
                  {rekOrNot && !q.text_rekvizit ? (
                    <img src={img} alt={q.values.rekvizit} />
                  ) : null}
                  <p>{rekOrNot ? gettowrite : towrite}</p>
                </div>
              ) : null;
            })}
            {this.state.questions[0].values.answer.trim() ? (
              <div className="b-question-item b-answer">
                <span>Cavab: </span>
                {this.state.questions[0].values.answer
                  ? `${this.state.questions[1].values.answer ? "1. " : ""} ${
                      this.state.questions[0].values.answer
                    }`
                  : null}
                {this.state.questions[1].values.answer
                  ? ` 2. ${this.state.questions[1].values.answer}`
                  : null}
                {this.state.questions[2].values.answer
                  ? ` 3. ${this.state.questions[2].values.answer}`
                  : null}
              </div>
            ) : null}
            {this.state.values.considered.trim() ? (
              <div className="b-question-item b-answer">
                <span>Sayılma meyarı: </span>
                {this.state.values.considered}
              </div>
            ) : null}
            {this.state.values.comment.trim() ? (
              <div className="b-question-item b-answer">
                <span>Şərh: </span>
                {this.state.values.comment}
              </div>
            ) : null}
            {this.state.values.sources.length > 0 ? (
              <div className="b-question-item b-answer">
                <p>
                  <span>Mənbə:&nbsp;</span>
                  {this.state.values.sources.map((q, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index > 0 ? (
                          <>
                            <br />{" "}
                            <span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </>
                        ) : null}
                        {this.state.values.sources.length > 1
                          ? index + 1 + ". " + q
                          : q}
                      </React.Fragment>
                    );
                  })}
                </p>
              </div>
            ) : null}
            {/* {this.state.values.source.trim() ? (
                            <div className="b-question-item b-answer">
                                <span>Mənbə: </span>
                                {this.state.values.source}
                            </div>
                        ) : null} */}
            {this.state.selectedAuthors.length > 0 ? (
              <div className="b-question-item b-answer">
                <span>
                  {this.state.selectedAuthors.length > 1
                    ? "Müəlliflər: "
                    : "Müəllif: "}
                </span>
                {this.state.selectedAuthors.map((aut, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && index < this.state.selectedAuthors.length
                      ? ", "
                      : ""}
                    {aut.label}
                  </React.Fragment>
                ))}
              </div>
            ) : null}
            {/* <div className="b-question-item b-answer">
                            <span>Çətinlik səviyyəsi: </span>
                            {this.state.level}/5
            </div> */}
            <div className="b-question-item mt-10">
              <Button onClick={this.submitQuestion} type="primary">
                Əlavə et
              </Button>
            </div>
          </div>
        </div>
        <div onClick={() => this.setModalVisible(true)} className="cont-box">
          <Icon type="user-add" />
        </div>
        <Modal
          title="Müəllif əlavə et"
          cancelText="Ləğv et"
          okText="Əlavə et"
          centered
          visible={this.state.modalVisible}
          onOk={this.addEditor}
          onCancel={() => this.setModalVisible(false)}
        >
          <label>Müəllifin tam adı</label>
          <Input value={this.state.newEditor} onChange={this.handleNewEditor} />
        </Modal>
      </>
    );
  }
}

export default connect(null, { loadingOn, loadingOff })(Type1);
