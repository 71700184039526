import React from "react";
import {
  Select,
  Modal,
  Input,
  Button,
  Icon,
  Switch,
  Spin,
  message
} from "antd";
// import './../splitstyle/split.css';
import "./style/types.css";
import sualtap from "./../../../../const/api";
import { connect } from "react-redux";
import { loadingOn, loadingOff } from "./../../../../redux/actions";
const { Option } = Select;

class Type4 extends React.Component {
  constructor(props) {
    super(props);
    this.lastAuthorFetchId = 0;
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      allAuthors: [],
      modalVisible: false,
      newEditor: "",
      selectedAuthors: [],
      authors: [],
      authorsFetching: false,
      totalRekvizit: false,
      type: 1,
      noteBefore: false,
      isTranslated: false,
      // level: 1,
      questions: [
        {
          values: {
            question: "",
            answer: ""
          }
        }
      ],
      values: {
        comment: "",
        noteBefore: "",
        considered: "",
        source: "",
        sources: []
      }
    };
  }

  componentDidMount() {
    this.fetchAuthors();
  }
  fetchAuthors = async () => {
    await sualtap.get("authors").then(res => {
      this.setState({
        allAuthors: res.data
      });
    });
  };
  resetState() {
    message.success("Sual uğurla əlavə olundu");
    this.setState(this.getInitialState());
    this.fetchAuthors();
  }
  setModalVisible = val => {
    this.setState({ modalVisible: val });
  };
  handleNewEditor = e => {
    this.setState({ newEditor: e.target.value });
  };
  addEditor = async e => {
    await sualtap
      .post(`add/author`, JSON.stringify({ fullname: this.state.newEditor }))
      .then(
        this.setState(
          { newEditor: "", modalVisible: false },
          message.warning("Yeni redaktor əlavə olundu")
        )
      );
  };
  // Authors search
  filterAuthorsSearch = async value => {
    this.lastAuthorFetchId += 1;
    const authorFetchId = this.lastAuthorFetchId;
    this.setState({ authors: [], authorsFetching: true });
    let authores = this.state.allAuthors.filter(a =>
      a.fullname.toLowerCase().includes(value.toLowerCase())
    );
    if (authorFetchId !== this.lastAuthorFetchId) {
      return;
    }
    this.setState({ authors: authores, authorsFetching: false });
  };
  handleAuthorsSearchChange = value => {
    this.setState({
      selectedAuthors: value,
      authors: [],
      authorsFetching: false
    });
  };
  handleTypeChange = e => {
    this.setState({ type: parseInt(e.target.value) });
  };
  // handleLevelChange = e => {
  //     this.setState({ level: parseInt(e.target.value) });
  // };
  handleIsTranslatedChange() {
    this.setState({ isTranslated: !this.state.isTranslated });
  }
  renderNoteBefore() {
    return this.state.noteBefore ? (
      <textarea
        onChange={e => this.handleNoteBeforeTextChange(e)}
        value={this.state.values.noteBefore}
        placeholder="Sualöncəsi qeyd"
      ></textarea>
    ) : null;
  }
  handleNoteBeforeChange() {
    this.setState({
      noteBefore: !this.state.noteBefore
    });
  }
  renderQuestions() {
    let resp = [];
    for (let i = 0; i < this.state.type; i++) {
      resp.push(
        <div key={i} className="single-question">
          {this.state.type > 1 ? (
            <div className="question-number">{i + 1}</div>
          ) : null}
          <textarea
            placeholder="Sualın mətnini daxil edin"
            onChange={e => this.handleQuestionTextChange(e, i)}
            value={this.state.questions[i].values.question}
          ></textarea>
          <input
            type="text"
            value={this.state.questions[i].values.answer}
            onChange={e => this.handleAnswerChange(e, i)}
            placeholder="Sualın cavabı"
          />
        </div>
      );
    }
    return resp.map(a => a);
  }
  handleQuestionTextChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].values.question = e.target.value;
    this.setState({
      questions: obj
    });
  }
  handleCommentChange(e) {
    const obj = this.state.values;
    obj.comment = e.target.value;
    this.setState({
      values: obj
    });
  }
  handleAnswerChange(e, i) {
    const obj = [...this.state.questions];
    obj[i].values.answer = e.target.value;
    this.setState({
      questions: obj
    });
  }
  handleConsideredChange(e) {
    const obj = this.state.values;
    obj.considered = e.target.value;
    this.setState({
      values: obj
    });
  }
  handleNoteBeforeTextChange(e) {
    const obj = this.state.values;
    obj.noteBefore = e.target.value;
    this.setState({
      values: obj
    });
  }
  handleSourceChange(e) {
    const obj = this.state.values;
    obj.source = e.target.value;
    obj.sources =
      e.target.value.trim() !== "" ? e.target.value.trim().split("/-/") : [];
    this.setState({
      values: obj
    });
  }
  submitQuestion = () => {
    if (this.validateQuestion()) {
      this.props.loadingOn();
      sualtap
        .post(
          "questions/nhn",
          JSON.stringify({
            ...this.state,
            authors: this.state.selectedAuthors.map(s => parseInt(s.key)),
            phase: parseInt(this.props.params.id)
          })
        )
        .then(res => {
          this.props.loadingOff();
          this.resetState();
          this.props.fetch();
        });
    }
  };
  isNullOrEmpty(str) {
    return str === null || str.trim().length === 0 ? true : false;
  }
  validateQuestion = () => {
    let validated = true;
    const firstText = this.state.type > 1 ? "1-ci sualın " : "Sualın ";
    if (this.state.questions[0].values.question.trim().length < 10) {
      message.warning(firstText + "mətnini daxil edin");
      validated = false;
    }
    if (this.state.questions[0].values.answer.trim().length < 1) {
      message.warning(firstText + "cavabını daxil edin");
      validated = false;
    }
    return validated;
  };

  render() {
    return (
      <>
        <div className="b-add-box">
          <div className="b-add-box-left bg-white">
            {!this.props.isNotOriginal ? (
              <Switch
                className="mt-10"
                checkedChildren="Sual tərcümədir"
                onChange={() => this.handleIsTranslatedChange()}
                unCheckedChildren="Sual originaldır"
                checked={this.state.isTranslated}
              />
            ) : null}
            <Switch
              className="mt-10"
              checkedChildren="Sualöncəsi qeyd var"
              onChange={() => this.handleNoteBeforeChange()}
              unCheckedChildren="Sualöncəsi qeyd yoxdur"
              checked={this.state.noteBefore}
            />
            {this.renderNoteBefore()}
            {this.renderQuestions()}
            <div className="question-details">
              <textarea
                value={this.state.values.comment}
                onChange={e => this.handleCommentChange(e)}
                placeholder="Sualın şərhi"
              ></textarea>
              <input
                type="text"
                value={this.state.values.considered}
                onChange={e => this.handleConsideredChange(e)}
                placeholder="Sayılma meyarı"
              />
              <input
                type="text"
                value={this.state.values.source}
                onChange={e => this.handleSourceChange(e)}
                placeholder="Mənbə"
              />
              <Select
                mode="multiple"
                labelInValue
                value={this.state.selectedAuthors}
                placeholder="Müəllifləri seç"
                notFoundContent={
                  this.state.authorsFetching ? <Spin size="small" /> : null
                }
                filterOption={false}
                onSearch={this.filterAuthorsSearch}
                onChange={this.handleAuthorsSearchChange}
                style={{ width: "100%" }}
              >
                {this.state.authors.map(a => (
                  <Option key={a.id}>{a.fullname}</Option>
                ))}
              </Select>
            </div>
            {/* <div className="b-level-radios mt-10 w-100">
                            <Radio.Group
                                defaultValue={this.state.level.toString()}
                                buttonStyle="solid"
                                onChange={this.handleLevelChange}
                            >
                                <Radio.Button value="1">1</Radio.Button>
                                <Radio.Button value="2">2</Radio.Button>
                                <Radio.Button value="3">3</Radio.Button>
                                <Radio.Button value="4">4</Radio.Button>
                                <Radio.Button value="5">5</Radio.Button>
                            </Radio.Group>
                        </div> */}
          </div>
          <div className="b-add-box-right bg-white">
            <h3>
              <b>Sual nömrə: {this.props.number}</b>
            </h3>
            {this.state.values.noteBefore.trim() ? (
              <div className="b-question-item b-answer">
                <span>Sualöncəsi qeyd: </span>
                {this.state.values.noteBefore}
              </div>
            ) : null}
            {this.state.questions.map(q => {
              const key = this.state.questions.indexOf(q) + 1;
              return key <= this.state.type ? (
                <div key={key} className="b-question-content">
                  <p> {q.values.question}</p>
                </div>
              ) : null;
            })}
            {this.state.questions[0].values.answer.trim() ? (
              <div className="b-question-item b-answer">
                <span>Cavab: </span>
                {this.state.questions[0].values.answer}
              </div>
            ) : null}
            {this.state.values.considered.trim() ? (
              <div className="b-question-item b-answer">
                <span>Sayılma meyarı: </span>
                {this.state.values.considered}
              </div>
            ) : null}
            {this.state.values.comment.trim() ? (
              <div className="b-question-item b-answer">
                <span>Şərh: </span>
                {this.state.values.comment}
              </div>
            ) : null}
            {this.state.values.sources.length > 0 ? (
              <div className="b-question-item b-answer">
                <p>
                  <span>Mənbə:&nbsp;</span>
                  {/* {this.state.source} */}
                  {this.state.values.sources.map((q, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index > 0 ? (
                          <>
                            <br />{" "}
                            <span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </>
                        ) : null}
                        {index + 1 + ". " + q}
                      </React.Fragment>
                    );
                  })}
                </p>
              </div>
            ) : null}
            {this.state.selectedAuthors.length > 0 ? (
              <div className="b-question-item b-answer">
                <span>
                  {this.state.selectedAuthors.length > 1
                    ? "Müəlliflər: "
                    : "Müəllif: "}
                </span>
                {this.state.selectedAuthors.map((aut, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && index < this.state.selectedAuthors.length
                      ? ", "
                      : ""}
                    {aut.label}
                  </React.Fragment>
                ))}
              </div>
            ) : null}
            {/* <div className="b-question-item b-answer">
                            <span>Çətinlik səviyyəsi: </span>
                            {this.state.level}/5
            </div> */}
            <div className="b-question-item mt-10">
              <Button onClick={this.submitQuestion} type="primary">
                Əlavə et
              </Button>
            </div>
          </div>
        </div>
        <div onClick={() => this.setModalVisible(true)} className="cont-box">
          <Icon type="user-add" />
        </div>
        <Modal
          title="Müəllif əlavə et"
          cancelText="Ləğv et"
          okText="Əlavə et"
          centered
          visible={this.state.modalVisible}
          onOk={this.addEditor}
          onCancel={() => this.setModalVisible(false)}
        >
          <label>Müəllifin tam adı</label>
          <Input value={this.state.newEditor} onChange={this.handleNewEditor} />
        </Modal>
      </>
    );
  }
}

export default connect(null, { loadingOn, loadingOff })(Type4);
