import React from "react";
import sualtap from "../../../const/api";
import { InputNumber, Input, Button, Select, Spin, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { connect } from "react-redux";
import history from "../../../const/history";
import { loadingOff, loadingOn } from "../../../redux/actions";
const { Option } = Select;

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.lastAuthorFetchId = 0;
    this.state = {
      theId: 1,
      question: {
        children: [],
      },
      allAuthors: [],
      selectedAuthors: [],
      authors: [],
      authorsFetching: false,
    };
  }

  getQuestion = async () => {
    await sualtap.get(`questions/${this.state.theId}`).then((res) => {
      let arr = [];
      res.data.author_ofs.forEach((el) => {
        const label = this.state.allAuthors.find((l) => l.id === el.author_ID)
          .fullname;
        arr.push({ key: el.author_ID.toString(), label });
      });
      this.setState({ question: res.data, selectedAuthors: arr });
    });
  };

  // Authors search
  filterAuthorsSearch = async (value) => {
    this.lastAuthorFetchId += 1;
    const authorFetchId = this.lastAuthorFetchId;
    this.setState({ authors: [], authorsFetching: true });
    let authores = this.state.allAuthors.filter((a) =>
      a.fullname.toLowerCase().includes(value.toLowerCase())
    );
    if (authorFetchId !== this.lastAuthorFetchId) {
      return;
    }
    this.setState({ authors: authores, authorsFetching: false });
  };
  handleAuthorsSearchChange = (value) => {
    this.setState({
      selectedAuthors: value,
      authors: [],
      authorsFetching: false,
    });
  };
  fetchAuthors = async () => {
    await sualtap.get("authors").then((res) => {
      this.setState({
        allAuthors: res.data,
      });
    });
  };

  //child question
  handleChildTextChange = (e, index) => {
    let question = { ...this.state.question };
    question.children[index].text = e.target.value;
    this.setState({
      question,
    });
  };
  handleChildAnswerChange = (e, index) => {
    let question = { ...this.state.question };
    question.children[index].answer = e.target.value;
    this.setState({
      question,
    });
  };
  handlePhaseChange = (e) =>
    this.setState({ question: { ...this.state.question, phase_ID: e } });
  changeChildQuestion = async (e, index) => {
    let obj = this.state.question.children[index];
    await sualtap
      .put(`questions/${obj.id}`, obj)
      .then((res) => message.success("Sualda düzəliş edildi"));
  };
  //text, comment, noteBefore, answer

  handleIdChange = (e) => this.setState({ theId: e });
  handleAnswerChange = (e) => {
    this.setState({
      question: { ...this.state.question, answer: e.target.value },
    });
  };
  handleTextChange = (e) => {
    this.setState({
      question: { ...this.state.question, text: e.target.value },
    });
  };
  handleNoteBeforeChange = (e) => {
    this.setState({
      question: { ...this.state.question, noteBefore: e.target.value },
    });
  };
  handleCommentChange = (e) => {
    this.setState({
      question: { ...this.state.question, comment: e.target.value },
    });
  };
  handleConsideredChange = (e) => {
    this.setState({
      question: { ...this.state.question, considered: e.target.value },
    });
  };

  componentDidMount = async () => {
    this.props.loadingOn();
    await this.fetchAuthors();
    if (!this.props.head) {
      let phase = 0;
      await sualtap.get(`questions/${this.props.params.id}`).then((res) => {
        phase = res.data.phase_ID;
        let arr = [];
        res.data.author_ofs.forEach((el) => {
          const label = this.state.allAuthors.find((l) => l.id === el.author_ID)
            .fullname;
          arr.push({ key: el.id.toString(), label });
        });
        this.setState({
          question: res.data,
          selectedAuthors: arr,
          theId: this.props.params.id,
        });
      });
      await sualtap.get(`questions/byphase?phase=${phase}`).then((res) => {
        if (res.data.user !== this.props.user.user_ID) {
          history.push("/my");
        }
      });
      this.props.loadingOff();
    } else {
      this.props.loadingOff();
    }
  };

  fixAuthors = async () => {
    let selected = [];
    this.state.selectedAuthors.forEach((el) => {
      selected.push(+el.key);
    });
    let old = [];
    this.state.question.author_ofs.forEach((el) => {
      old.push(el.author_ID);
    });
    //keep - 1    //update - 2    //delete - 3    //new - 4
    let copySelected = [...selected];
    let copyOld = [...old];
    let process = [];
    copySelected.forEach((el) => {
      if (copyOld.includes(el)) {
        process.push({ action: 1, id: el, val: null });
        copySelected = copySelected.filter((a) => a !== el);
        copyOld = copyOld.filter((a) => a !== el);
      }
    });
    copyOld.forEach((el) => {
      if (copySelected.length) {
        process.push({ action: 2, id: el, val: copySelected[0] });
        copySelected.shift();
        copyOld = copyOld.filter((a) => a !== el);
      } else {
        process.push({ action: 3, id: el, val: null });
      }
    });
    copySelected.forEach((el) => {
      process.push({ action: 4, id: el, val: null });
    });
    let success = true;
    process.forEach((el) => {
      switch (el.action) {
        case 1:
          return;
        case 2:
          let author_update = this.state.question.author_ofs.filter(
            (ao) => ao.author_ID === el.id
          )[0];
          author_update.author_ID = el.val;
          sualtap
            .put(`author_of/${author_update.id}`, author_update)
            .then((error) => (success = false));
          break;
        case 3:
          let author_delete = this.state.question.author_ofs.filter(
            (ao) => ao.author_ID === el.id
          )[0];
          sualtap
            .delete(`author_of/${author_delete.id}`)
            .then((error) => (success = false));
          break;
        case 4:
          let author_new = {
            author_ID: el.id,
            question_ID: this.state.theId,
            theme_ID: null,
          };
          sualtap
            .post("author_of", author_new)
            .then((error) => (success = false));
          break;
        default:
          break;
      }
    });
    if (success) {
      message.success("Müəlliflərdə düzəliş edildi");
    }
  };

  changeQuestion = async () => {
    await sualtap
      .put(`questions/${this.state.theId}`, this.state.question)
      .then((res) => console.log(res));
  };

  render() {
    return (
      <div className="editPage">
        {this.props.head ? (
          <div className="edit-part" style={{ width: 200 }}>
            <InputNumber
              type="number"
              value={this.state.theId}
              min={1}
              onChange={(e) => this.handleIdChange(e)}
            />
            <Button onClick={this.getQuestion} type="primary">
              Axtar
            </Button>
          </div>
        ) : null}
        {this.state.question !== null ? (
          <>
            <div className="edit-parts">
              <div className="edit-question">
                <label>Sualöncəsi qeyd</label>
                <div className="label-field">
                  <TextArea
                    onChange={(e) => this.handleNoteBeforeChange(e)}
                    value={this.state.question.noteBefore}
                    rows={2}
                  />
                </div>
              </div>
              <div className="edit-question">
                <label>Sualın mətni</label>
                <div className="label-field">
                  <TextArea
                    onChange={(e) => this.handleTextChange(e)}
                    value={this.state.question.text}
                    rows={4}
                  />
                </div>
              </div>
              <div className="edit-question">
                <label>Sualın cavabı</label>
                <div className="label-field">
                  <TextArea
                    onChange={(e) => this.handleAnswerChange(e)}
                    value={this.state.question.answer}
                    rows={1}
                  />
                </div>
              </div>
              <div className="edit-question">
                <label>Sualın şərhi</label>
                <div className="label-field">
                  <TextArea
                    onChange={(e) => this.handleCommentChange(e)}
                    value={this.state.question.comment}
                    rows={4}
                  />
                </div>
              </div>
              <div className="edit-question">
                <label>Sayılma meyarı</label>
                <div className="label-field">
                  <TextArea
                    onChange={(e) => this.handleConsideredChange(e)}
                    value={this.state.question.considered}
                    rows={2}
                  />
                </div>
              </div>
              <div className="edit-question">
                <Button type="primary" onClick={this.changeQuestion}>
                  Dəyiş
                </Button>
              </div>
              <div className="edit-question">
                {this.props.user.role === 5 ? (
                  <>
                    <label>Tur</label>
                    <div className="label-field">
                      <InputNumber
                        onChange={(e) => this.handlePhaseChange(e)}
                        value={this.state.question.phase_ID}
                      />
                    </div>
                  </>
                ) : null}
                <div className="label-field">
                  <Select
                    mode="multiple"
                    labelInValue
                    value={this.state.selectedAuthors}
                    placeholder="Müəllifləri seç"
                    notFoundContent={
                      this.state.authorsFetching ? <Spin size="small" /> : null
                    }
                    filterOption={false}
                    onSearch={this.filterAuthorsSearch}
                    onChange={this.handleAuthorsSearchChange}
                    style={{ width: "100%" }}
                  >
                    {this.state.authors.map((a) => (
                      <Option key={a.id}>{a.fullname}</Option>
                    ))}
                  </Select>
                  <Button type="primary" onClick={this.fixAuthors}>
                    Müəllifləri düzəlt{" "}
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : null}
        <div>
          {this.state.question.children.map((q, index) => {
            return (
              <div key={index} className="theme-edit-parts">
                <label>{index + 2}. Sualın mətni</label>
                <div className="label-field">
                  <TextArea
                    onChange={(e) => this.handleChildTextChange(e, index)}
                    value={q.text}
                    rows={3}
                  />
                </div>
                <label>Sualın cavabı</label>
                <div className="label-field">
                  <Input
                    onChange={(e) => this.handleChildAnswerChange(e, index)}
                    value={q.answer}
                  />
                </div>
                <Button
                  type="primary"
                  onClick={(e) => this.changeChildQuestion(e, index)}
                >
                  Dəyiş {index + 2}
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user: user.data,
  };
};

export default connect(mapStateToProps, { loadingOn, loadingOff })(Edit);